(function( $ ) {

    $(document).on('change', '#product-content-info select#rqty, #product-content-info select#date', function (e) {

        var id = $(this).attr("tourid");

        var rqty = $('#'+id+' select#rqty').val();
        var rqtyActual = rqty.split(",");

        var id = rqtyActual[2]; 

        var rprice = parseInt($('#'+id+' #price').val());
        var rdate = $('#'+id+' select#date').val();

        var newdate = rdate.split(","); 

        if(parseInt(newdate[1]) < parseInt(rqtyActual[0])) {  
            $( '#'+id+' #add-tour-cart' ).addClass( "btn-disable" );
        } else {
            $( '#'+id+' #add-tour-cart' ).removeClass( "btn-disable" );  
        }

        $('#'+id+' #startdate').val(newdate[0]); 
        $('#'+id+' #lstartdate').val(newdate[2]); 
        $('#'+id+' #qty').val(rqtyActual[0]);         

        console.log('1');
    }); 

    $(document).on('change', '#exp-booking select#qty, #exp-booking select#date', function (e) { 

        var rqty = parseFloat($('#exp-booking select#qty').val());
        var rprice = parseFloat($('#exp-booking #price').val());
        var minGuest = parseFloat($('#exp-booking #minguest').val());

        var rdate = $('#exp-booking select#date').val();
        var currency = $('#exp-booking #currency-symbol').val(); 
        var rqtyActual = parseFloat(rdate.split(",").pop());
        var currency2 = $("#rezdy-data #currency_symbol").val(); 

        var startdate = rdate.split(",");
        var totalPrice = rqty * rprice;

        $('#rezdy-data .woocommerce-Price-amount').html(currency2 + totalPrice.toLocaleString("en-US")); 

        if(rqty < minGuest) {
            $( ".exp-booking .book-footer#minimum" ).addClass( "active" );
               
            $( ".exp-booking .book-footer#minimum" ).removeClass( "deactivate" );
            $( ".exp-booking .book-footer#avail" ).addClass( "deactivate active" );
            $( ".exp-booking .book-footer#no-avail" ).removeClass( "deactivate active" );
 
            return false;
        }

        if(startdate[1] >= rqty) {
                           
            $( ".exp-booking .book-footer#avail" ).addClass( "active" );
            $( ".exp-booking .book-footer#no-avail" ).addClass( "deactivate" );

            $( ".exp-booking .book-footer#no-avail" ).removeClass( "active" );
            $( ".exp-booking .book-footer#minimum" ).removeClass( "active" );
            $( ".exp-booking .book-footer#avail" ).removeClass( "deactivate" );            

            $('#rezdy-data #startdate').val(startdate[0]);
            $('#rezdy-data #lstartdate').val(startdate[2]);
            $('#rezdy-data #qty').val(rqty);            

        } else {
            
            $( ".exp-booking .book-footer#no-avail" ).addClass( "active" );
            $( ".exp-booking .book-footer#avail" ).addClass( "deactivate" );
 
            $( ".exp-booking .book-footer#avail" ).removeClass( "active" );
            $( ".exp-booking .book-footer#minimum" ).removeClass( "active" );
            $( ".exp-booking .book-footer#no-avail" ).removeClass( "deactivate" );
            
            $('#rezdy-data #startdate').val(startdate[0]);
            $('#rezdy-data #lstartdate').val(startdate[2]); 
            $('#rezdy-data #qty').val(rqty);            
        } 

        $('#rezdy-data #final-amount').html(currency2 + totalPrice.toLocaleString("en-US"));                 
   
    });

    $(document).on('click', '.exp-booking .book-footer#avail a#book-now', function () { 

        var productCode = $("#rezdy-data #productCode").attr("value");
        var price = $("#rezdy-data #price").attr("value");
        var rezdy_price = $("#rezdy-data #rezdy_price").attr("value");
        var startdate = $("#rezdy-data #startdate").val();
        var lstartdate = $("#rezdy-data #lstartdate").val();
        var qty = $("#rezdy-data #qty").val(); 
        var id = $("#rezdy-data #id").val(); 
        var currency = $("#rezdy-data #currency").val();    
        var rezdy_currency = $("#rezdy_currency").val();
        var minguest = $("#minguest").val();        


        if(parseInt(qty) < parseInt(minguest)) {  
            $( ".exp-booking .book-footer#minimum" ).addClass( "active" );
               
            $( ".exp-booking .book-footer#minimum" ).removeClass( "deactivate" );
            $( ".exp-booking .book-footer#avail" ).addClass( "deactivate active" );
            $( ".exp-booking .book-footer#no-avail" ).removeClass( "deactivate active" );
 
            return false;            
        } 
        
        $.ajax({ 
            type: "POST",
            url: madmonkey_tickets_options.ajaxurl,
            data: {
                action: 'rezdy_add_to_cart', 
                id : id, 
                qty : qty, 
                productCode : productCode,  
                startdate : startdate,
                lstartdate : lstartdate,
                rezdy_price : rezdy_price,
                currency : currency,        
                rezdy_currency : rezdy_currency,        
                price : price                
        },
        beforeSend: function() {
            $(".exp-detail").addClass('overlay');
            $("#alert-loading").addClass('d-block');            
        },
        complete: function() {
            $(".exp-detail").removeClass('overlay');
            $("#alert-loading").removeClass('d-block');            
        },
        success: function( response ) {

           window.location.href = '/checkout';  

        },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                console.log("Status: " + textStatus);
                console.log("Error: " + errorThrown);
            }
        });

    });

    // check prize and calendar in rezdy
    $.fn.rezdyPrice = function() {
        if ($('#popup-tours-section').length) {

            $('#popup-tours-section .product').each(function () {                
                
                var id = $(this).attr('id');
                var gstartdate = $("#gstartdate").val();
                var genddate = $("#genddate").val(); 

                $.ajax({ 
                    type: "POST",
                    url: madmonkey_tickets_options.ajaxurl,
                    data: {
                        action: 'rezdy_check_price',                                    
                        id : id, 
                        gstartdate : gstartdate,                
                        genddate : genddate
                },            
                success: function( response ) {
        
                    $("#"+ id + " #product-content-info").html(response);
                    
                    // remove skeleton
                    $("#"+ id + " #product-content-info").removeClass('skeleton');
        
                },
                    error: function(XMLHttpRequest, textStatus, errorThrown) {
                        console.log("Status: " + textStatus);
                        console.log("Error: " + errorThrown);
                    }
                });
            });            
        }
    }

    $(document).on('change', '#popup-tours-section #product-content-info select#rqty, #product-content-info select#date', function (e) {

        var id = $(this).attr('tourid'); 
                
        var proid = $("#"+id+" #proid").attr("value");
        var productCode = $("#"+id+" #productCode").attr("value");
        var price = $("#"+id+" #price").attr("value");
        var rezdy_price = $("#"+id+" #rezdy_price").attr("value");
        var startdate = $("#"+id+" #startdate").val();
        var lstartdate = $("#"+id+" #lstartdate").val();
        var qty = $("#"+id+" #qty").val();         
        var currency = $("#"+id+" #currency").val(); 
        var rezdy_currency = $("#"+id+" #rezdy_currency").val(); 
        var minguest = $("#"+id+" #minguest").val(); 

        $.ajax({ 
            type: "POST",
            url: madmonkey_tickets_options.ajaxurl,
            data: {
                action: 'rezdy_add_to_cart', 
                id : proid,  
                qty : qty, 
                productCode : productCode,  
                startdate : startdate,
                lstartdate : lstartdate,
                rezdy_price : rezdy_price,
                currency : currency,
                rezdy_currency : rezdy_currency,
                minguest : minguest,
                price : price                        
        },
        beforeSend: function() {
            $(".popup-footer-start span").hide();
            $("#alert-loading").addClass('d-block');            
        },
        complete: function() {
            $(".popup-footer-start span").show();
            $("#alert-loading").removeClass('d-block');            
        },
        success: function( response ) { 

            $(".popup-footer-start span").html(response);

            jQuery('body').trigger('update_checkout');

        },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                console.log("Status: " + textStatus);
                console.log("Error: " + errorThrown);
            }
        });

    });

    
    $(document).one('click', '.tour#show-popup-data', function () {                  
        $('body').rezdyPrice();                
    });

    $( document ).ready( function() {
        if ($('#madventure-single').length) {
            var rqty = parseFloat($('#exp-booking select#qty').val());             
            var rdate = $('#exp-booking select#date').val();

            var startdate = rdate.split(",");

            $('#rezdy-data #startdate').val(startdate[0]);
            $('#rezdy-data #lstartdate').val(startdate[2]); 
            $('#rezdy-data #qty').val(rqty);            
        }
    });

})(jQuery);

