(function( $ ) {

  $.fn.readMore = function() {
    //This limit you can set after how much characters you want to show Read More.
    var carLmt = 180;
    // Text to show when text is collapsed
    var readMoreTxt = " READ MORE";
    // Text to show when text is expanded
    var readLessTxt = " READ LESS";


    //Traverse all selectors with this class and manupulate HTML part to show Read More
    $(".addReadMore").each(function() {
        if ($(this).find(".firstSec").length)
            return;

        var allstr = $(this).text();

        if (allstr.length > carLmt) {
            var firstSet = allstr.substring(0, carLmt);
            var secdHalf = allstr.substring(carLmt, allstr.length);
            var strtoadd = firstSet + "<span class='SecSec'>" + secdHalf + "</span><span class='readMore'  title='Click to Show More'>" + readMoreTxt + "</span><span class='readLess' title='Click to Show Less'>" + readLessTxt + "</span>";
            $(this).html(strtoadd);
        }

    }); 

    //Read More and Read Less Click Event binding
    $(document).on("click", ".readMore,.readLess", function() {
        $(this).closest(".addReadMore").toggleClass("showlesscontent showmorecontent");
    });
  }

  $.fn.mobileCalendar = function() {

    // mobile calendar
      $(document).on('click', '.mobile .icalendar', function (e) {
        $("#mdatepicker").attr('style',  'display:inline-flex');
      });

      $('#mdatepicker').click(function (e) {
        e.stopPropagation();
      });

      if ($('#mdatepicker').length) {
        $(document).click(function(e) {
      
          if(e.target.id !== 'mcheck-in' && e.target.id !== 'mcheck-out'&& e.target.class !== undefined) {
            //alert(e.target.class);
            $("#mdatepicker").hide();
          }
        });
      }
      
      $(document).click(function(e) { 
        if ($(e.target).closest(".cart-information, .booking-counntry-search, .popup-rooms .icalendar").length === 0) {
          $("#mdatepicker").hide();
        }
      });


      var startDate, endDate;

      var datepicker = {
          container: $("#mdatepicker"),
          dateFormat: 'MM d, yy',
          dates: [null, null],
          status: null,
          inputs: {
            checkin: $('#mcheck-in'),
            checkout: $('#mcheck-out'),
            dates: $('#mdates')
          }
      };

      $('.cart-information #mdatepicker').append('<div class="datepicker-buttons"><button class="cancel">Cancel</button><button class="submit">Submit</button></div>');

      $('.datepicker-buttons .cancel').on('click', function(){ 
        $("#mdatepicker").hide();
      });

      $('.datepicker-buttons .submit').on('click', function(){ 
        $("#booking-counntry-cart #submit").trigger('click');
      });

      $('#mlist-country').on('change', function(){

        $('.book-now button').addClass('active');
      });

      datepicker.container.datepicker({
        numberOfMonths: 1,
        dateFormat: datepicker.dateFormat,
        minDate: 0,
        ignoreReadonly: true,
        allowInputToggle: true,
        maxDate: null,

        beforeShowDay: function(date) {
          var highlight = false,
              currentTime = date.getTime(),
              selectedTime = datepicker.dates,
              checkin_date = selectedTime[0] ? new Date(selectedTime[0]) : null,
              checkout_date = selectedTime[1] ? new Date(selectedTime[1]) : null,
              checkin_timestamp,
              checkout_timestamp,
              classes = 'ui-datepicker-highlight';

              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              date.setMilliseconds(0);

              currentTime = date.getTime();

              // CHECKIN/CHECKOUT CLASSES
              if(checkin_date) {
                checkin_date.setHours(0);
                checkin_date.setMinutes(0);
                checkin_date.setSeconds(0);
                checkin_date.setMilliseconds(0);
                checkin_timestamp = checkin_date.getTime();

                startDate = checkin_timestamp;
              }

              if(checkout_date) {
                checkout_date.setHours(0);
                checkout_date.setMinutes(0);
                checkout_date.setSeconds(0);
                checkout_date.setMilliseconds(0);
                checkout_timestamp = checkout_date.getTime();

                endDate = checkout_timestamp;
              }

              if ( checkin_timestamp && currentTime == checkin_timestamp ) {
                classes = 'ui-datepicker-highlight ui-checkin';
              } else if (checkout_timestamp && currentTime == checkout_timestamp) {
                classes = 'ui-datepicker-highlight ui-checkout';
              }

              // Highlight date range
              if ((selectedTime[0] && selectedTime[0] == currentTime) || (selectedTime[1] && (currentTime >= selectedTime[0] && currentTime <= selectedTime[1]))) highlight = true;

          return [true, highlight ? classes : ""];
        },
        onSelect: function(dateText) {

          $('.book-now button').addClass('active');

          if (!datepicker.dates[0] || datepicker.dates[1] !== null) {

          var destination = $('.mlist-country').val();

          let startDate = new Date(dateText);
          let endDate = new Date(startDate);
          endDate = new Date(endDate.setDate(endDate.getDate() + 14));

          if(destination == '41'){

            $('#mdatepicker').datepicker('option', 'minDate', startDate);
            $('#mdatepicker').datepicker('option', 'maxDate', endDate);
          } 

          // fill dates array with first chosen date
          datepicker.dates[0] = $.datepicker.parseDate(datepicker.dateFormat, dateText).getTime();
          datepicker.dates[1] = null;

          // clear all inputs
        datepicker.inputs.checkin.val('');
          datepicker.inputs.checkout.val('');
        datepicker.inputs.dates.val('');

          // set current datepicker state
          datepicker.status = 'checkin-selected';

          // create mouseover for table cell
          $('#datepicker').delegate('.ui-datepicker td', 'mouseover', function(){

          // if it doesn't have year data (old month or unselectable date)
          if ($(this).data('year') == undefined) return;

          // datepicker state is not in date range select, depart date wasn't chosen, or return date already chosen then exit
          if (datepicker.status != 'checkin-selected') return;

            // get date from hovered cell
            var hoverDate = $(this).data('year')+'-'+($(this).data('month')+1)+'-'+$('a',this).html();

            // parse hovered date into milliseconds
            hoverDate = $.datepicker.parseDate('yy-mm-dd', hoverDate).getTime();

            $('#datepicker td').each(function(){

                // compare each table cell if it's date is in date range between selected date and hovered
                if ($(this).data('year') == undefined) return;

                var year = $(this).data('year'),
                    month = $(this).data('month'),
                    day = $('a', this).html();

                var cellDate = $(this).data('year')+'-'+($(this).data('month')+1)+'-'+$('a',this).html();

                // convert cell date into milliseconds for further comparison
                cellDate = $.datepicker.parseDate('yy-mm-dd', cellDate).getTime();

                if ( (cellDate >= datepicker.dates[0] && cellDate <= hoverDate) || (cellDate <= datepicker.dates[0] && cellDate >= hoverDate) ) {
                    $(this).addClass('ui-datepicker-hover');
                  } else {
                    $(this).removeClass('ui-datepicker-hover');
                  }

              });
            });

        } else {
          // CHOOSE SECOND DATE

          // push second date into dates array
          datepicker.dates[1] = $.datepicker.parseDate(datepicker.dateFormat, dateText).getTime();

          // sort array dates
          datepicker.dates.sort();

          var checkInDate = $.datepicker.parseDate('@', datepicker.dates[0]);
          var checkOutDate = $.datepicker.parseDate('@', datepicker.dates[1]);

          datepicker.status = 'checkout-selected';

          //fill input fields
          datepicker.inputs.checkin.val($.datepicker.formatDate(datepicker.dateFormat, checkInDate));
          datepicker.inputs.checkout.val($.datepicker.formatDate(datepicker.dateFormat, checkOutDate)).change();
          datepicker.inputs.dates.val(datepicker.inputs.checkin.val() + ' - ' + datepicker.inputs.checkout.val());

          $('#mcheck-in-alter').val($.datepicker.formatDate('yy-mm-dd', checkInDate));
          $('#mcheck-out-alter').val($.datepicker.formatDate('yy-mm-dd', checkOutDate));

          $('.mbooking #mcheck-in').val($.datepicker.formatDate('M d', checkInDate) + "-" + $.datepicker.formatDate('d, yy', checkOutDate));

          $("#mdatepicker").hide();


                  }
              }
      });
  }

  $(document).on('click', 'a#mobile-calendar-search', function () {

    $('#custom_fc_button').hide();
    $('.booking-counntry-search').addClass("active");
    $('.layout-backdrop').attr('aria-hidden', 'false');

    return false;
  });

  $(document).click(function (e) {
    if ($(e.target).parents("form#mcalendar-booking-search").length === 0) {
     
      $('.booking-counntry-search').removeClass("active"); 
    }
  });

  $('#mcalendar-booking-search > button > span').click(function (e) {
    
    $('.booking-counntry-search').removeClass("active");
    $('.layout-backdrop').attr('aria-hidden', 'true');
  });


  // mobile calendar
  $(document).on('click', '.mobile .icalendar', function (e) {
    $("#mdatepicker").attr('style',  'display:inline-flex');
  });

  $('#mdatepicker').click(function (e) {
    e.stopPropagation();
  });

  if ($('#mdatepicker').length) {
    $(document).click(function(e) {

      if(e.target.id !== 'mcheck-in' && e.target.id !== 'mcheck-out' && e.target.class !== undefined) {
        //alert(e.target.class);
        $("#mdatepicker").hide();
      }
    });
  }

  $(document).on('click', function(e) { 
    if ($(e.target).closest(".cart-information, .booking-counntry-search, .popup-rooms .icalendar").length === 0) {
      $("#mdatepicker").hide();
    }
  });


var startDate, endDate;

var datepicker = {
    container: $("#mdatepicker"),
    dateFormat: 'MM d, yy',
    dates: [null, null],
    status: null,
    inputs: {
      checkin: $('#mcheck-in'),
      checkout: $('#mcheck-out'),
      dates: $('#mdates')
    }
};

$('.cart-information #mdatepicker').append('<div class="datepicker-buttons"><button class="cancel">Cancel</button><button class="submit">Submit</button></div>');

$('.datepicker-buttons .cancel').on('click', function(){ 
  $("#mdatepicker").hide();
});

$('.datepicker-buttons .submit').on('click', function(){ 
  $("#booking-counntry-cart #submit").trigger('click');
});

$('.popup-rooms #submit.mobile').on('click', function(event){ 
  // event.stopPropagation();
});

datepicker.container.datepicker({
  numberOfMonths: 1,
  dateFormat: datepicker.dateFormat,
  minDate: 0,
  ignoreReadonly: true,
  allowInputToggle: true,
  maxDate: null,
  
  beforeShowDay: function(date) {

    var highlight = false,
      currentTime = date.getTime(),
      selectedTime = datepicker.dates,
      checkin_date = selectedTime[0] ? new Date(selectedTime[0]) : null,
      checkout_date = selectedTime[1] ? new Date(selectedTime[1]) : null,
      checkin_timestamp,
      checkout_timestamp,
      classes = 'ui-datepicker-highlight';

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    currentTime = date.getTime();

    // CHECKIN/CHECKOUT CLASSES
    if(checkin_date) {
      checkin_date.setHours(0);
      checkin_date.setMinutes(0);
      checkin_date.setSeconds(0);
      checkin_date.setMilliseconds(0);
      checkin_timestamp = checkin_date.getTime();

      startDate = checkin_timestamp;
    }

    if(checkout_date) {
      checkout_date.setHours(0);
      checkout_date.setMinutes(0);
      checkout_date.setSeconds(0);
      checkout_date.setMilliseconds(0);
      checkout_timestamp = checkout_date.getTime();

      endDate = checkout_timestamp;
    }

    if ( checkin_timestamp && currentTime == checkin_timestamp ) {
      classes = 'ui-datepicker-highlight ui-checkin';
    } else if (checkout_timestamp && currentTime == checkout_timestamp) {
      classes = 'ui-datepicker-highlight ui-checkout';
    }

    // Highlight date range
    if ((selectedTime[0] && selectedTime[0] == currentTime) || (selectedTime[1] && (currentTime >= selectedTime[0] && currentTime <= selectedTime[1]))) highlight = true;

    return [true, highlight ? classes : ""];
  },
  onSelect: function(dateText) {

    $('.book-now button').addClass('active');

    if (!datepicker.dates[0] || datepicker.dates[1] !== null) {

      var destination = $('.mlist-country').val();

      let startDate = new Date(dateText);
      let endDate = new Date(startDate);
      endDate = new Date(endDate.setDate(endDate.getDate() + 14));

      if(destination == '41'){

        $('#mdatepicker').datepicker('option', 'minDate', startDate);
        $('#mdatepicker').datepicker('option', 'maxDate', endDate);
      } 

    // fill dates array with first chosen date
    datepicker.dates[0] = $.datepicker.parseDate(datepicker.dateFormat, dateText).getTime();
    datepicker.dates[1] = null;

    // clear all inputs
	  datepicker.inputs.checkin.val('');
    datepicker.inputs.checkout.val('');
	  datepicker.inputs.dates.val('');

    // set current datepicker state
    datepicker.status = 'checkin-selected';

    // create mouseover for table cell
    $('#datepicker').delegate('.ui-datepicker td', 'mouseover', function(){

    // if it doesn't have year data (old month or unselectable date)
    if ($(this).data('year') == undefined) return;

    // datepicker state is not in date range select, depart date wasn't chosen, or return date already chosen then exit
    if (datepicker.status != 'checkin-selected') return;

      // get date from hovered cell
      var hoverDate = $(this).data('year')+'-'+($(this).data('month')+1)+'-'+$('a',this).html();

      // parse hovered date into milliseconds
      hoverDate = $.datepicker.parseDate('yy-mm-dd', hoverDate).getTime();

      $('#datepicker td').each(function(){

          // compare each table cell if it's date is in date range between selected date and hovered
          if ($(this).data('year') == undefined) return;

          var year = $(this).data('year'),
              month = $(this).data('month'),
              day = $('a', this).html();

          var cellDate = $(this).data('year')+'-'+($(this).data('month')+1)+'-'+$('a',this).html();

          // convert cell date into milliseconds for further comparison
          cellDate = $.datepicker.parseDate('yy-mm-dd', cellDate).getTime();

          if ( (cellDate >= datepicker.dates[0] && cellDate <= hoverDate) || (cellDate <= datepicker.dates[0] && cellDate >= hoverDate) ) {
              $(this).addClass('ui-datepicker-hover');
            } else {
              $(this).removeClass('ui-datepicker-hover');
            }

        });
      });

      $('#mcalendar-booking-search button').removeAttr('disabled');

  } else {
    // CHOOSE SECOND DATE

    // push second date into dates array
    datepicker.dates[1] = $.datepicker.parseDate(datepicker.dateFormat, dateText).getTime();

    // sort array dates
	  datepicker.dates.sort();

    var checkInDate = $.datepicker.parseDate('@', datepicker.dates[0]);
    var checkOutDate = $.datepicker.parseDate('@', datepicker.dates[1]);

    datepicker.status = 'checkout-selected';

    //fill input fields
    datepicker.inputs.checkin.val($.datepicker.formatDate(datepicker.dateFormat, checkInDate));
	  datepicker.inputs.checkout.val($.datepicker.formatDate(datepicker.dateFormat, checkOutDate)).change();
	  datepicker.inputs.dates.val(datepicker.inputs.checkin.val() + ' - ' + datepicker.inputs.checkout.val());

    $('#mcheck-in-alter').val($.datepicker.formatDate('yy-mm-dd', checkInDate));
    $('#mcheck-out-alter').val($.datepicker.formatDate('yy-mm-dd', checkOutDate));

    $('.mbooking #mcheck-in').val($.datepicker.formatDate('M d', checkInDate) + "-" + $.datepicker.formatDate('d, yy', checkOutDate));
    
    $('.maddon-checkin').html($.datepicker.formatDate('MM d', checkInDate));
    $('.maddon-checkout').html($.datepicker.formatDate('MM d', checkOutDate));

    $("#mdatepicker").hide();

    $('#mcalendar-booking-search button').removeAttr('disabled');


            }
        }
});

$(document).on('click', "#booking-counntry-cart #submit", function () {

	var category = $('#mlist-country option:selected').val();
	var datecheckin = $('#mcheck-in-alter').val();
	var datecheckout = $('#mcheck-out-alter').val();
  var country = $('#country').val();
  var country = $('#country').val();
  var qrcode = $('#qrcode').val();


  $('#promo-rate .counter').val(0);
  $('#regular-rate .counter').val(0); 
 
	if(category == '41') { 

    var win = window.open("https://hotels.cloudbeds.com/reservation/muXJte#checkin=" + datecheckin + "&checkout=" + datecheckout, '_blank');
	  win.focus();
	  return false;
	}

	if(category == '') {
	  $("#destination").addClass("input-error");
	} else {
	  $("#destination").removeClass("input-error");
	}

	if(datecheckin == '') {
	  $("#check-in").addClass("input-error");
	  } else {
	  $("#check-in").removeClass("input-error");
	  }

	if(datecheckout == '') {
	  $("#check-out").addClass("input-error");
	} else {
	  $("#check-out").removeClass("input-error");
	}

	if(datecheckin == '' || datecheckout == '') {
	  return false;
	}

  $('body').show_room_skeleton();

	$('body').show_shoppingcart_skeleton();

  $('.right h2.destination, .currency-exchange, .right h2.destination + p').addClass('skeleton');
  $('.room-type').addClass('sk-loader');

	wp.ajax.post({ action: 'calendar_search', category, datecheckin, datecheckout, country, qrcode}).done(function(response) {

	  $('#rooms').html(response);

	  $("#spinner-ajax").hide();
    
	  $('#rooms').attr('style','visibility: visible');

	  if ($('.right .private').length) {
		$('.room-type .item a#private-room').show();
	  }

	  if ($('.right .dorm').length) {
		$('.room-type .item a#dorm-room').show();
	  }

    currency();

	  $('body').promoChecker();

	  var nprice = $("p#total-price").attr("total");

	  if(nprice != undefined && nprice != 0) {
      $("#book-now-mobile h2 span").html(" | " + $("p#total-price").attr("total"));
      $( "#book-now-mobile" ).addClass( "active" );
	  } else {
      $("#book-now-mobile h2 span").html(" ");
      $( "#book-now-mobile" ).removeClass( "active" );
	  }

	  $('body').mobileCalendar();

    $('body').swipeBooking();

    $('body').hide_shoppingcart_skeleton();
		$('body').hide_room_skeleton();
    
    $('.right h2.destination, .currency-exchange, .right h2.destination + p').removeClass('skeleton');
    $('.room-type').removeClass('sk-loader');

	});
});

$('#mlist-country').on('change', function(){

  $('.book-now button').addClass('active');

  $('#mcheck-in').val('');
  $('#mcheck-in-alter').val('');
  $('#mcheck-out').val('');
  $('#mcheck-out-alter').val('');
  $('#mdates').val('');

  $('#mdatepicker .ui-datepicker-calendar').addClass('remove-hover');

  setTimeout(function(){
    $("#mdatepicker").datepicker('refresh');
  }, 150);

  datepicker.dates[0] = null;
  datepicker.dates[1] = null;

  $('#mdatepicker').datepicker('option', 'minDate', 0);
  $('#mdatepicker').datepicker('option', 'maxDate', null);
});

//
function formatState(state) {
	if (!state.id) {
		return state.text;
	}
	var baseUrl = "https://madmonkeyhostels.com/wp-content/themes/madmonkey-tickets/assets/images/icons8-currency.png";

	var $state = $(
		'<span><img class="img-flag" /> <span></span></span>'
	);

	$state.find("span").text(state.text);
	$state.find("img").attr("src", baseUrl);

	return $state;
};

function currency(){

  const $currency = $('.currency');

	$currency.select2({
		templateSelection: formatState,
		minimumResultsForSearch: -1,
		width: '135px',
		dropdownParent: $('.currency-exchange')
	});
}

})(jQuery);



