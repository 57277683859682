var qr_code = (function(document, window) {

	var evt = [

		function(document, window) {

      var element = document.querySelectorAll('[data-target=bounceleft]');

      if(element) {

				element.forEach(function(elem) {
					
					var delay = elem.getAttribute('data-delay');

					setTimeout(function() {
						elem.classList.add('bounceInLeft');
					}, delay);
				})
      }
		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

document.addEventListener('DOMContentLoaded', function () {
  
  qr_code.init();
});