var ui_slider = (function(document) {

	var evt = [

		function(document) {

			var swipers = document.querySelectorAll('.swiper-holder');

			swipers.forEach(function(slides){

				var swipe = slides.querySelector('.swiper');

				var perviewSm = swipe.getAttribute('data-sm-perview');
				var perviewMd = swipe.getAttribute('data-md-perview');
				var perviewLg = swipe.getAttribute('data-lg-perview');
				var pagination = slides.querySelector('.swiper-pagination');
				var effect = swipe.getAttribute('data-effect');
				var delay = swipe.getAttribute('data-delay');
				var next = slides.querySelector('.swiper-button-next');
				var prev = slides.querySelector('.swiper-button-prev');

				swipe = new Swiper(swipe, {
					spaceBetween: 8,
					slidesPerView: perviewSm,
					effect: effect,
					centeredSlides: true,
					slideToClickedSlide: true,
					speed: 500,
					loop: true,
					autoHeight: false,
					coverflowEffect: {
						rotate: 0,
						stretch: -40,
						depth: 190,
						modifier:0.4,
						slideShadows: false,
					},
					pagination: {
						el: pagination,
					},
					navigation: {
						nextEl: next,
						prevEl: prev,
					},
					breakpoints: {
						560: {
							slidesPerView: perviewSm,
							spaceBetween: 8,
						},
						576: {
							slidesPerView: perviewMd,
							spaceBetween: 25,
						},
						1024: {
							slidesPerView: perviewLg,
							spaceBetween: 25,
						},
					},
				});

				var videos = document.querySelectorAll('.video-player');

				var currentSlide = document.querySelector('.swiper-holder .swiper-slide.swiper-slide-active');
				var currentVideo = currentSlide.querySelector('.video-player');

				// Detect if in viewport
				function isInViewport(el) {
					var top = el.offsetTop;
					var left = el.offsetLeft;
					var width = el.offsetWidth;
					var height = el.offsetHeight;
				
					while(el.offsetParent) {
						el = el.offsetParent;
						top += el.offsetTop;
						left += el.offsetLeft;
					}
				
					return (
						top < (window.pageYOffset + window.innerHeight) &&
						left < (window.pageXOffset + window.innerWidth) &&
						(top + height) > window.pageYOffset &&
						(left + width) > window.pageXOffset
					);
				}

				// play video if in viewport
				function play_video(){

					var swiper_video = document.querySelector('.swiper-video');

					if(isInViewport(swiper_video)) {

						currentVideo.play();
					}
				}

				videos.forEach(function(video){

					play_video();

					document.addEventListener('scroll', function(){

						play_video();
					});
	
					currentVideo.addEventListener('ended', function() {
	
						swipe.slideNext();
					});
	
					swipe.on("slideChange", function (){
	
						var index_currentSlide = swipe.activeIndex;
						var currentSlide = swipe.slides[index_currentSlide];
	
						var activeVideo = currentSlide.querySelector('.video-player');
	
						video.pause();
						video.currentTime = 0;

						activeVideo.play();				
	
						activeVideo.addEventListener('ended', function() {
	
							swipe.slideNext();
						});
			
					});	
				});



			});

			var swipers2 = document.querySelectorAll('.swiper-holder-2');

			swipers2.forEach(function(slides){

				var swipe = slides.querySelector('.swiper');

				var perviewSm = swipe.getAttribute('data-sm-perview');
				var perviewMd = swipe.getAttribute('data-md-perview');
				var perviewLg = swipe.getAttribute('data-lg-perview');
				var loop = swipe.getAttribute('data-loop');
				var gap = swipe.getAttribute('data-gap');
				var height = swipe.getAttribute('data-autoheight');
				var pagination = slides.querySelector('.swiper-pagination');
				var effect = swipe.getAttribute('data-effect');
				var delay = swipe.getAttribute('data-delay');
				var autoplay = swipe.getAttribute('data-autoplay');
				var next = slides.querySelector('.swiper-button-next');
				var prev = slides.querySelector('.swiper-button-prev');
				var swipe_slide  = swipe.querySelectorAll('.swiper-slide');

				var new_loop = JSON.parse(loop);
				var new_gap = parseFloat(gap);
				var new_height = JSON.parse(height);
				var new_autoplay = JSON.parse(autoplay);

				swipe = new Swiper(swipe, {
					spaceBetween: new_gap,
					slidesPerView: perviewSm,
					effect: effect,
					centeredSlides: true,
					speed: 500,
					loop: new_loop,
					autoHeight: new_height,
					autoplay: {
						enabled: new_autoplay,
						delay: delay,
						disableOnInteraction: false,
						pauseOnMouseEnter: false,
					},
					pagination: {
						el: pagination,
						clickable: true,
					},
					navigation: {
						nextEl: next,
						prevEl: prev,
					},
					breakpoints: {
						560: {
							slidesPerView: perviewSm,
						},
						576: {
							slidesPerView: perviewMd,
						},
						1024: {
							slidesPerView: perviewLg,
						},
					},
					on: {
						beforeInit() {
							const swipe_slide = this.el.querySelectorAll('.swiper-slide');
							const destination_swiper = document.querySelector('.destination-cards');
				
							if (destination_swiper) {
								this.params.loop = swipe_slide.length > 2;
								this.params.autoplay.enabled = swipe_slide.length > 2;
								this.params.centeredSlides = swipe_slide.length > 2;
							} 

							if(swipe_slide.length < 3) {

								swipe.classList.add('no-blur');
								this.el.classList.add('swiper-no-swiping');
							} else {

								slides.querySelector('.swiper').classList.add('blur');
							}
						},
					},
				});

				if (swipe_slide.length < perviewSm) {
					
					slides.querySelector('.swiper').classList.add('swiper-no-swiping');

					var buttons = slides.querySelectorAll('.swiper-button-icon');

					buttons.forEach(function(button){

						button.classList.add('swiper-button-hidden');
					});
				}

				if(new_autoplay == false) {

					swipe.autoplay.stop();
				} else {

					swipe.autoplay.start();
				}

			});

			var swipers3 = document.querySelectorAll('.swiper-holder-3');

			swipers3.forEach(function(slides){

				var swipe = slides.querySelector('.swiper');

				var perviewSm = swipe.getAttribute('data-sm-perview');
				var perviewMd = swipe.getAttribute('data-md-perview');
				var perviewLg = swipe.getAttribute('data-lg-perview');
				var gap = swipe.getAttribute('data-gap');
				var pagination = slides.querySelector('.swiper-pagination');
				var effect = swipe.getAttribute('data-effect');
				var next = slides.querySelector('.swiper-button-next');
				var prev = slides.querySelector('.swiper-button-prev');
				var swipe_slide  = swipe.querySelectorAll('.swiper-slide');
				var loop = swipe.getAttribute('data-loop');

				var new_gap = parseFloat(gap);
				var new_loop = JSON.parse(loop);

				swipe = new Swiper(swipe, {
					spaceBetween: new_gap,
					slidesPerView: perviewSm,
					effect: effect,
					loop: new_loop,
					centeredSlides: false,
					grabCursor: true,
					preventClicks :true,
					// slidesPerGroup: 1,
					speed: 500,
					shortSwipes: 20,
					a11y: false,
					freeMode: {
						enabled: false,
					},
					pagination: {
						el: pagination,
						clickable: true,
					},
					navigation: {
						nextEl: next,
						prevEl: prev,
					},
					breakpoints: {
						560: {
							slidesPerView: perviewSm,
						},
						576: {
							slidesPerView: perviewMd,

						},
						1024: {
							slidesPerView: perviewLg,
						},
					},
				});

				if (swipe_slide.length < perviewLg) {
					
					slides.querySelector('.swiper-wrapper').classList.add('swiper-wrapper-center');
				}

			});

			var swipers_filter = document.querySelectorAll('.swiper-filter');

			swipers_filter.forEach(function(slides){

				var swipe = slides.querySelector('.swiper');

				var perviewSm = swipe.getAttribute('data-sm-perview');
				var perviewMd = swipe.getAttribute('data-md-perview');
				var perviewLg = swipe.getAttribute('data-lg-perview');
				var gap = swipe.getAttribute('data-gap');
				var pagination = slides.querySelector('.swiper-pagination');
				var effect = swipe.getAttribute('data-effect');
				var next = slides.querySelector('.swiper-button-next');
				var prev = slides.querySelector('.swiper-button-prev');

				var new_gap = parseFloat(gap);

				var swiper = new Swiper(swipe, {
					spaceBetween: new_gap,
					slidesPerView: perviewSm,
					effect: effect,
					loop: false,
					centeredSlides: false,
					grabCursor: true,
					preventClicks :true,
					speed: 500,
					slidesPerGroup: 2,
					shortSwipes: 20,
					a11y: false,
					freeMode: {
						enabled: false,
					},
					pagination: {
						el: pagination,
						clickable: true,
					},
					navigation: {
						nextEl: next,
						prevEl: prev,
					},
					breakpoints: {
						560: {
							slidesPerView: perviewSm,
						},
						576: {
							slidesPerView: perviewMd,

						},
						1024: {
							slidesPerView: perviewLg,
						},
					},
				});

				var filters = document.querySelectorAll('#list-country_s a');

				filters.forEach(function(buttons){
					
					buttons.addEventListener('click', function(event){
						event.preventDefault();

						var target = buttons.getAttribute('href');
						var active = document.querySelector('#list-country_s a.active');

						if(active){
							
							active.classList.remove('active');
						}

						event.currentTarget.classList.add('active');

						var filters = document.querySelectorAll('[data-filter]');
						var country_card = document.querySelectorAll('.swiper-country[data-filter]');

						if( target == 'all') {

							filters.forEach(function(filter){

								filter.classList.remove('non-swiper-slide');
								filter.classList.add('swiper-slide');
								filter.style.display = 'block';
								
								country_card.forEach(function(c_card){
									c_card.style.display = 'none';
								});
							});

							swiper.updateSlides();
							swiper.updateProgress();
							swiper.updateSlidesClasses();
							swiper.slideTo(0);
							swiper.scrollbar.updateSize();
							
							return false;

						} else {

							var newTargets = document.querySelectorAll('[data-filter="'+target+'"]');

							filters.forEach(function(filter){

								filter.classList.add('non-swiper-slide');
								filter.classList.remove('swiper-slide');
								filter.style.display = 'none';
							});

							newTargets.forEach(function(newTarget){

								newTarget.classList.remove('non-swiper-slide');
								newTarget.classList.add('swiper-slide');
								newTarget.style.display = 'block';
							});

							swiper.updateSlides();
							swiper.updateProgress();
							swiper.updateSlidesClasses();
							swiper.slideTo(0);
							swiper.scrollbar.updateSize();
							
							return false;
							
						}
					});

				});
				
			});

			var swipers_thumb = document.querySelectorAll('.swiper-holder-thumb');

			swipers_thumb.forEach(function(slides){

				var swipe = slides.querySelector('.swiper');
				var navigations =  slides.querySelector('.swiper-pagination');
				
				var menu = JSON.parse(decodeURIComponent(navigations.dataset.toshow));

				var perviewSm = swipe.getAttribute('data-sm-perview');
				var perviewMd = swipe.getAttribute('data-md-perview');
				var perviewLg = swipe.getAttribute('data-lg-perview');
				var loop = swipe.getAttribute('data-loop');
				var gap = swipe.getAttribute('data-gap');
				var pagination = slides.querySelector('.swiper-pagination');
				var effect = swipe.getAttribute('data-effect');
				var delay = swipe.getAttribute('data-delay');
				var next = slides.querySelector('.swiper-button-next');
				var prev = slides.querySelector('.swiper-button-prev');

				var new_gap = parseFloat(gap);

				swipe = new Swiper(swipe, {
					spaceBetween: new_gap,
					slidesPerView: perviewSm,
					effect: effect,
					centeredSlides: true,
					slideToClickedSlide: true,
					loop: false,
					speed: 500,
					autoHeight: false,
					// noSwiping: true,
					// noSwipingClass: 'swiper-slide',
					zoom: {
						maxRatio: 1.6,
						minRatio: 1
					},
					pagination: {
						el: pagination,
						clickable: true,
						renderBullet: function (index, className) {
							return '<span class="' + className + '">' + (menu[index]) + '</span>';
						},
					},
					navigation: {
						nextEl: next,
						prevEl: prev,
					},
					breakpoints: {
						560: {
							slidesPerView: perviewSm,
						},
						576: {
							slidesPerView: perviewMd,

						},
						1024: {
							slidesPerView: perviewLg,
						},
					},
				});

			});

			var swipers_auto = document.querySelectorAll('.swiper-holder-auto');

			swipers_auto.forEach(function(slides){

				var swipe = slides.querySelector('.swiper');

				var perviewSm = swipe.getAttribute('data-sm-perview');
				var perviewMd = swipe.getAttribute('data-md-perview');
				var perviewLg = swipe.getAttribute('data-lg-perview');
				var gap = swipe.getAttribute('data-gap');
				var effect = swipe.getAttribute('data-effect');

				var new_gap = parseFloat(gap);

				swipe = new Swiper(swipe, {
					spaceBetween: new_gap,
					slidesPerView: perviewSm,
					slidesPerGroup: 1,
					centeredSlides: true,
					effect: effect,
					loop: true,			
					speed: 6000,
					shortSwipes: 20,
					a11y: false,
					noSwiping: true,
					noSwipingClass: 'swiper-slide',
					allowTouchMove: false,
					freeMode: {
						enabled: true,
					},
					autoplay: {
						delay: 0,
						disableOnInteraction: false,
						pauseOnMouseEnter: false,
						waitForTransition: false,
					},
					breakpoints: {
						560: {
							slidesPerView: perviewSm,
						},
						576: {
							slidesPerView: perviewMd,

						},
						1024: {
							slidesPerView: perviewLg,
						},
					}
				});

			});

		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

ui_slider.init();
document.addEventListener("DOMContentLoaded", function() {
});

document.addEventListener("resize", function() {
	ui_slider.init();
});