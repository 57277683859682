import { Alert } from "./_bootstrap";

(function( $ ) {

$('#ticket-info-popup').on('hidden.bs.modal', function () {
  location.reload();
})

if ($('#checkin-golden').length) {
  $('#activate-ticket').attr('disabled', true);
}

$( "#checkin-golden" ).datepicker({
    dateFormat: 'MM d, yy',
    altField: "#check-in-alter",
    altFormat: "yy-mm-dd",
    minDate: 0,
    ignoreReadonly: true,
    allowInputToggle: true,
    onSelect: function(selected, evnt) {
      var checkin = $("#check-in-alter").val();
      var days = $("#days").val();

     if(days == 14) {

        var add_days = 14;

     } else if(days == 30) {

        var add_days = 30;

      } else if(days == 60) {

        var add_days = 60;

      } else if(days == 90) {

        var add_days = 90;
      } else if(days == 15) {

        var add_days = 15;
      }

      var readableD = new Date(checkin);
      var golden_ticket = new Date(new Date(checkin).setDate(new Date(checkin).getDate() + add_days));

      $('#check-out-alter').val($.datepicker.formatDate('yy-mm-dd', golden_ticket));

      $('#checkin-golden').val(readableD.toDateString().split(' ').slice(1).join(' ')+" - "+golden_ticket.toDateString().split(' ').slice(1).join(' '));

     // $("p#notice-mssg").text("Your ticket will activate on "+readableD.toDateString()+" and end on "+golden_ticket.toDateString());

      $('#error-mssg').hide();

      $("#activate-ticket").removeAttr('disabled');
  }
});

$( "#activate-ticket" ).click(function() {

  var checkin = $("#check-in-alter").val();
  var checkout = $("#check-out-alter").val();
  var days = $("#days").val();
  var id = $("#ticketid").val();
  var index = $("#index").val();
  var product = $("#product").val();
  var productid = $("#productid").val();

  if(checkin == '') {

    $('#error-mssg').show();

    return false;
  }

  $( "#activate-ticket" ).hide();

  wp.ajax.post({ action: 'activate_ticket', checkin, checkout, days, id, index, product, productid}).done(function(response) {

    $('.modal-header h2').hide();
    $('#activate-golden-ticket').html(response);

  });
});

$(document).on('click', '#mobile-golder-ticket', function () {

  var id = $(this).attr('data');

  $(this).toggleClass('active inactive');

  $('td.product-'+id).toggleClass('active');

});

$(document).on('click', '#ticket-item', function () {

  var title = $(this).attr('title');
	var data = $(this).attr('data');
	var days = $(this).attr('days');
  var index = $(this).attr('index');
  var product = $(this).attr('product');
  var productid = $(this).attr('productid');

  $('span.days-ticket').text($(this).attr('days'));

  $("p#ticket-title").text(title);
	$("p#ticket-details").text(data);

	$("input#ticketid").val(data);
	$("input#days").val(days);
  $("input#index").val(index);
  $("input#product").val(product);
  $("input#productid").val(productid);

});

setTimeout(function() {
  $('.woocommerce-notices-wrapper, .woocommerce-message, .notice, .notice-valid').fadeOut('fast');
}, 3000);

$('#datepicker').click(function (e) {
	e.stopPropagation();
});

if ($('#datepicker').length) {
	$(document).on('click', function(e) {

    var container = $("#check-in, #check-out, .remove-date, .date, .open-calendar");

    if (!container.is(e.target) && container.has(e.target).length === 0) {
      
			$("#datepicker").hide();

      if(datepicker.inputs.checkin.val() == '') {

        $('.booking-search .item').find('.remove-checkin').hide();
      } 

      if(datepicker.inputs.checkout.val() == '') {

        $('.booking-search .item').find('.remove-checkout').hide();
      } 
  	}

	});
  
  $(document).on('click', '#check-in, #check-out, .remove-date, .date', function (event) {
    event.stopPropagation();
    
    $("#datepicker").attr('style',  'display:inline-flex');
  });
}

// Booking Search
var startDate, endDate;

var datepicker = {
  container: $("#datepicker"),
  dateFormat: 'MM d, yy',
  dates: [null, null],
  status: null,
  inputs: {
    checkin: $('#check-in'),
    checkout: $('#check-out'),
    dates: $('#dates')
  }
};

datepicker.container.datepicker({
  numberOfMonths: 2,
  dateFormat: datepicker.dateFormat,
  minDate: 0,
  ignoreReadonly: true,
  allowInputToggle: true,
  maxDate: null,
  dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

  beforeShowDay: function(date) {
    var highlight = false,
      currentTime = date.getTime(),
      selectedTime = datepicker.dates,
      checkin_date = selectedTime[0] ? new Date(selectedTime[0]) : null,
      checkout_date = selectedTime[1] ? new Date(selectedTime[1]) : null,
      checkin_timestamp,
      checkout_timestamp,
      classes = 'ui-datepicker-highlight';

      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);

      currentTime = date.getTime();

      // CHECKIN/CHECKOUT CLASSES
      if(checkin_date) {
        checkin_date.setHours(0);
        checkin_date.setMinutes(0);
        checkin_date.setSeconds(0);
        checkin_date.setMilliseconds(0);
        checkin_timestamp = checkin_date.getTime();

        startDate = checkin_timestamp;
      }

      if(checkout_date) {
        checkout_date.setHours(0);
        checkout_date.setMinutes(0);
        checkout_date.setSeconds(0);
        checkout_date.setMilliseconds(0);
        checkout_timestamp = checkout_date.getTime();

        endDate = checkout_timestamp;
      }

      if ( checkin_timestamp && currentTime == checkin_timestamp ) {
        classes = 'ui-datepicker-highlight ui-checkin';
      } else if (checkout_timestamp && currentTime == checkout_timestamp) {
        classes = 'ui-datepicker-highlight ui-checkout';
      }
      
      // Highlight date range
      if ((selectedTime[0] && selectedTime[0] == currentTime) || (selectedTime[1] && (currentTime >= selectedTime[0] && currentTime <= selectedTime[1]))) highlight = true;

      if(datepicker.inputs.checkin.val() != '') {

        $('.booking-search #check-in').parents('.item').removeClass('active');
        $('.booking-search #check-out').parents('.item').addClass('active');

        $('.remove-checkin').show();
      }
        
    return [true, highlight ? classes : ""];
  },
  onSelect: function(dateText) {

    var LocId   = calenderFilterData.filter.id; 
    var Fildays = calenderFilterData.filter.days; 

    if (!datepicker.dates[0] || datepicker.dates[1] !== null) {

      if(datepicker.inputs.checkin.val() != '') {

        $('.booking-search #check-in').parents('.item').removeClass('active');
        $('.booking-search #check-out').parents('.item').addClass('active');
  
        $('.remove-checkin').show();
      }

      var destination = $('.destination-select').val();

      let startDate = new Date(dateText);
      let endDate = new Date(startDate); 

      if(destination == LocId) {
        
        endDate = new Date(endDate.setDate(endDate.getDate() + Fildays));
        startDate = new Date(startDate.setDate(startDate.getDate()));

        $('#datepicker').datepicker('option', 'minDate', startDate);
        $('#datepicker').datepicker('option', 'maxDate', endDate);
      }

      // fill dates array with first chosen date
      datepicker.dates[0] = $.datepicker.parseDate(datepicker.dateFormat, dateText).getTime();
      datepicker.dates[1] = null;

      var checkInDate = $.datepicker.parseDate('@', datepicker.dates[0]);

      // clear all inputs
      datepicker.inputs.checkin.val('');
      datepicker.inputs.checkout.val('');
      datepicker.inputs.dates.val('');

      // set current datepicker state
      datepicker.status = 'checkin-selected';

      datepicker.inputs.checkin.val($.datepicker.formatDate(datepicker.dateFormat, checkInDate));

      $('#check-in-alter').val($.datepicker.formatDate('yy-mm-dd', checkInDate));

      // create mouseover for table cell
      $('#datepicker').delegate('.ui-datepicker td', 'mouseover', function(){

      // if it doesn't have year data (old month or unselectable date)
      if ($(this).data('year') == undefined) return;

      // datepicker state is not in date range select, depart date wasn't chosen, or return date already chosen then exit
      if (datepicker.status != 'checkin-selected') return;

      // get date from hovered cell
      var hoverDate = $(this).data('year')+'-'+($(this).data('month')+1)+'-'+$('a',this).html();

      // parse hovered date into milliseconds
      hoverDate = $.datepicker.parseDate('yy-mm-dd', hoverDate).getTime();

      $('#datepicker td').each(function(){

          // compare each table cell if it's date is in date range between selected date and hovered
          if ($(this).data('year') == undefined) return;

          var year = $(this).data('year'),
            month = $(this).data('month'),
            day = $('a', this).html();

          var cellDate = $(this).data('year')+'-'+($(this).data('month')+1)+'-'+$('a',this).html();

          // convert cell date into milliseconds for further comparison
          cellDate = $.datepicker.parseDate('yy-mm-dd', cellDate).getTime();

          if(datepicker.inputs.checkin.val() != '' || datepicker.inputs.checkout.val() != '') {

            if ((cellDate >= datepicker.dates[0] && cellDate <= hoverDate) || (cellDate <= datepicker.dates[0] && cellDate >= hoverDate) ) {
              $(this).addClass('ui-datepicker-hover');

              setTimeout(function(){
                $('.ui-datepicker-hover').removeClass('first last');
                $('.ui-datepicker-hover').first().addClass('first');
                $('.ui-datepicker-hover').last().addClass('last');

              }, 10)
            } else {
              $(this).removeClass('ui-datepicker-hover');
              $(this).removeClass('first last');


            } 

            // $(".popup-footer-start span").html('<small>'+kl_checkout.event_data.CurrencySymbol+'</small> 0');

          }

        });
      });

    } else {
      // CHOOSE SECOND DATE

      // push second date into dates array
      datepicker.dates[1] = $.datepicker.parseDate(datepicker.dateFormat, dateText).getTime();

      // sort array dates
      datepicker.dates.sort();

      var checkInDate = $.datepicker.parseDate('@', datepicker.dates[0]);
      var checkOutDate = $.datepicker.parseDate('@', datepicker.dates[1]);

      datepicker.status = 'checkout-selected';

      //fill input fields
      datepicker.inputs.checkin.val($.datepicker.formatDate(datepicker.dateFormat, checkInDate));
      datepicker.inputs.checkout.val($.datepicker.formatDate(datepicker.dateFormat, checkOutDate)).change();
      datepicker.inputs.dates.val(datepicker.inputs.checkin.val() + ' - ' + datepicker.inputs.checkout.val());

      $('#check-in-alter').val($.datepicker.formatDate('yy-mm-dd', checkInDate));
      $('#check-out-alter').val($.datepicker.formatDate('yy-mm-dd', checkOutDate));

      $('.remove-checkout').show();
      $('.booking-search #check-out').parents('.item').removeClass('active');

      setTimeout(function() {
        $("#datepicker").hide();

        $('.booking-search .item').removeClass('active');
        $('.booking-search .container').removeClass('active');
      }, 600);

    }
  }
});

$(document).ready(function () {
  
  // remove date
  $(document).on('click', '.remove-checkin', function(){

    $('.remove-checkout').hide();

    $('#check-in').val('');
    $('#check-in-alter').val('');
    $('#check-out').val('');
    $('#check-out-alter').val('');
    $('#dates').val('');

    $('#datepicker .ui-datepicker-calendar').addClass('remove-hover');

    datepicker.dates[0] = null;
    datepicker.dates[1] = null;
    
    setTimeout(function(){
      $("#datepicker").datepicker('refresh');
      $("#datepicker").attr('style',  'display:inline-flex');

    }, 150);

    $('#datepicker').datepicker('option', 'minDate', 0);
    $('#datepicker').datepicker('option', 'maxDate', null);

  });

  $(document).on('click', '.remove-checkout', function(){

    datepicker.inputs.checkout.val('');

    $('#datepicker .ui-datepicker-calendar tbody td').removeClass('ui-datepicker-highlight ui-checkout');
    
    setTimeout(function(){
      $('.remove-checkin').show();
      $("#datepicker").attr('style',  'display:inline-flex');
      $('#datepicker .ui-checkin').trigger('click');
    }, 150);

  });

  $('.destination-select').on("change", function (e) {
      
    $('.remove-checkout').hide();

    $('#check-in').val('');
    $('#check-in-alter').val('');
    $('#check-out').val('');
    $('#check-out-alter').val('');
    $('#dates').val('');

    $('#datepicker .ui-datepicker-calendar').addClass('remove-hover');

    datepicker.dates[0] = null;
    datepicker.dates[1] = null;
    
    setTimeout(function(){
      $("#datepicker").datepicker('refresh');
      $("#datepicker").attr('style',  'display:inline-flex');
    }, 150);

    $('#datepicker').datepicker('option', 'minDate', 0);
    $('#datepicker').datepicker('option', 'maxDate', null);
  });

  var maxGuestEmails = 8;
  var guestEmailsCount = 1;

  $(document).on('click', '#add-guest-email', function(e) {
    e.preventDefault();
    if (guestEmailsCount < maxGuestEmails) {
      guestEmailsCount++;
      // Create a new element
      var newElement = $('<span class="woocommerce-input-wrapper"><input type="text" class="input-text " name="guest_email[]" placeholder="Guest email (optional)" value=""></span>');
      
      // Insert the new element before the parent of the clicked button
      $(this).parent().before(newElement);
      // $('#additional-guest-emails').append('<span class="woocommerce-input-wrapper"><input type="text" class="input-text " name="guest_email[]" placeholder="Guest email (optional)" value=""></span><br>');
    } else {
      alert('Sorry, you cannot add more than ' + maxGuestEmails + ' guest emails.');
    }
  });

  // Validate email addresses
  $(document).on('blur', '.guest-email', function() {
      var email = $(this).val();
      if (email !== '') {
          if (!isValidEmail(email)) {
              alert('Please enter a valid email address.');
              $(this).focus();
          }
      }
  });

  // Function to validate email format
  function isValidEmail(email) {
      var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
  }

});

// Show remove button on load
var checkIn = datepicker.inputs.checkin.val();
var checkOut = datepicker.inputs.checkout.val();

if(checkIn != '' && checkOut != '') {

  $('.remove-date').show();

  $('.booking-search .item').removeClass('active');
}


// archive search calendar
$(document).on('click', '#filter-calendar', function () {
    $(".mmkbooking-archive #mmkcalendar-search").slideDown( "slow" );

    $([document.documentElement, document.body]).animate({
      scrollTop: $("#body-top").offset().top
    }, 1000);

    
    setTimeout(function(){
      $("#datepicker").datepicker('refresh');
      $("#datepicker").attr('style',  'display:inline-flex');
      $('.booking-search #check-out').parents('.item').removeClass('active');

    }, 150);

});

//
function formatState(state) {
	if (!state.id) {
		return state.text;
	}
	var baseUrl = "https://madmonkeyhostels.com/wp-content/themes/madmonkey-tickets/assets/images/icons8-currency.png";

	var $state = $(
		'<span><img class="img-flag" /> <span></span></span>'
	);

	$state.find("span").text(state.text);
	$state.find("img").attr("src", baseUrl);

	return $state;
};

function currency(){

  const $currency = $('.currency');

	$currency.select2({
		templateSelection: formatState,
		minimumResultsForSearch: -1,
		width: '135px',
		dropdownParent: $('.currency-exchange')
	});
}
 

$(document).on('click', "#mmkcalendar-search #submit", function () {
  
	var category = $('#destination option:selected').val();
	var datecheckin = $('#check-in-alter').val();
	var datecheckout = $('#check-out-alter').val();
  var country = $('#country').val();
  var qrcode = $('#qrcode').val();

  $('#promo-rate .counter').val(0);
  $('#regular-rate .counter').val(0);

  if(category == '41') {

    var win = window.open("https://hotels.cloudbeds.com/reservation/muXJte#checkin=" + datecheckin + "&checkout=" + datecheckout, '_blank');

    win.focus();

    return false;
  }

	if(category == '') {
	  $("#destination").addClass("input-error");
	} else {
	  $("#destination").removeClass("input-error");
	}

	if(datecheckin == '') {
		$("#check-in").addClass("input-error");
	  } else {
		$("#check-in").removeClass("input-error");
	  }

	if(datecheckout == '') {
	  $("#check-out").addClass("input-error");
	} else {
	  $("#check-out").removeClass("input-error");
	}

	if(datecheckin == '' || datecheckout == '') {
	  return false;
	}
  
	// $("#spinner-ajax").show(); 
	// $('#rooms').attr('style','visibility: hidden');

	$('body').show_shoppingcart_skeleton();
  $('body').show_room_skeleton();


  $('.right h2.destination, .currency-exchange, .right h2.destination + p').addClass('skeleton');
  $('.room-type').addClass('sk-loader');

	wp.ajax.post({ action: 'calendar_search', category, datecheckin, datecheckout, country, qrcode}).done(function(response) {

		$('#rooms').html(response);

    var toggle = document.querySelectorAll('[data-toggle=readmore]');

			if(toggle) {
				toggle.forEach(function(collapse){
	
					var parent = collapse.parentNode;
	
					var target = parent.querySelector('.' + collapse.getAttribute('aria-controls'));
	
					collapse.addEventListener('click', function(event){
						event.preventDefault();
						if(target.classList.contains('show')) {
	
							target.classList.remove('show');
							collapse.innerHTML = 'READ MORE';
						} else {
							target.classList.add('show');
							collapse.innerHTML = 'READ LESS';
						}
					}, false);
				});
			}
    
		// $("#spinner-ajax").hide();
		// $('#rooms').attr('style','visibility: visible');
    
    currency();

    if ($('.right .private').length) {
      $('.room-type .item a#private-room').show();
    }

    if ($('.right .dorm').length) {
      $('.room-type .item a#dorm-room').show();
    }

    $('body').promoChecker();

    var nprice = $("p#total-price").attr("total");

    if(nprice != undefined) {
      $("#book-now-mobile h2 span").html(" | " + $("p#total-price").attr("total"));
      $( "#book-now-mobile" ).addClass( "active" );
    } else {
      $("#book-now-mobile h2 span").html(" ");
      $( "#book-now-mobile" ).removeClass( "active" );
    }

    $('body').mobileCalendar();
    $('body').swipeBooking();

    $('body').hide_shoppingcart_skeleton();
		$('body').hide_room_skeleton();

    $('.right h2.destination, .currency-exchange, .right h2.destination + p').removeClass('skeleton');
    $('.room-type').removeClass('sk-loader');

	});
});

$(document).on('click', '#flexible_refund', function () {
  $("#wc_checkout_add_ons input[name=e036327]").trigger('click');

  $("#no_refund").removeAttr("disabled");
  $(this).attr("disabled", true);

  $(this).prop('checked', true);
  $('#no_refund').prop('checked', false);

  $('.flexible-cancellation .item').removeClass('active');
  $(this).parent().parent().addClass('active');

});

$(document).on('click', '#no_refund', function () {
  $("#wc_checkout_add_ons input[name=e036327]").trigger('click');

  $("#flexible_refund").removeAttr("disabled");
  $(this).attr("disabled", true);

  $(this).prop('checked', true);
  $('#flexible_refund').prop('checked', false);

  $('.flexible-cancellation .item').removeClass('active');
  $(this).parent().parent().addClass('active');

});

$(document).on('click', '#payment-submit', function () {
  $( "#place_order" ).trigger( "click" );
});

$( "h5 .icon-question" ).hover(
  function() {
    $('#cancellation-desc').show();
  }, function() {
    $('#cancellation-desc').hide();
  }
);

$(document).on('click', '.count-wrapper a.cancellation', function () {
  $(this).next().slideToggle();
});

$(document).on('click', '.count-wrapper a.non-refund', function () {
  $(this).next().slideToggle();
});
 
// $('.cancellation-container').mouseenter(function(){
//   $('.fee-text-desc', this).show();  
// }).mouseleave(function(){
//   $('.fee-text-desc', this).hide();
// });

$(document).on('mouseenter', '.cancellation', function (event) {
  $(this).siblings('.fee-text-desc').show();
}).on('mouseleave', '.cancellation', function(){
  $(this).siblings('.fee-text-desc').hide();
});

$(document).on('click', '.cancel', function (event) {
  event.preventDefault();
});

$(document).ready(function() {

  // Currency

  $("#wc_checkout_add_ons input[name=e036327]").prop('checked', true);


  $(document).on('click', '.sitenav .currency a', function () {
	$(".currency-modal").modal();
  });

  $(document).on('click', '.sitenav .login-popup a, .tail .checkout-btn.login a, #sitenav-mobile ul li.login-popup a, .price-table a.login-popup', function () {
	  $(".login-modal").modal();
    $('.welcome-message').hide();
    $('.popup-login-wrapper').show();
  });

  $(document).on('click', '.tail', function(e) {
    if ( $(this).hasClass('active') === false ) {
      e.preventDefault();
    }
  });

  $(document).on('click', '.price-table a.login-popup', function () {
	  $(".login-modal").modal();
  });

  // login placeholder
  $(".login-modal #user_login").attr("placeholder", "Email");
  $(".login-modal #user_pass").attr("placeholder", "Password");

  var count_room = $(".right .room").length;

  if(count_room <= 0) {
    //$(".mmkbooking-archive #mmkcalendar-search").slideToggle();
  }


  // Booking Style

  var book_field = $('.booking-search');

  $('.booking-search .item input, .booking-search .remove-date').on('click', function(){

    book_field.find('.container').addClass('active');

    book_field.find('.item').removeClass('active');

    $(this).parents('.item').addClass('active');

  });

  $('.booking-search .item-check input, .date').on('click', function(){

    book_field.find('.container').addClass('active');

    book_field.find('.item').removeClass('active');

    $(this).parents('.item').addClass('active');

  });

  $('.booking-search .item:first-child').on('click', function(){

    book_field.find('.container').addClass('active');

    book_field.find('.item').removeClass('active');

    $(this).addClass('active');

  });

  $('.open-destination').on('click', function(){

    book_field.find('.item-check').removeClass('active');

  });

  $('.open-calendar').on('click', function(){

    book_field.find('.places-item').removeClass('active');

  });

  // remove style on click outside
  $(document).on('click', function (e) {
    var container = $(".open-destination, .booking-search .item, .open-calendar");

    if ($(e.target).closest(".booking-search .container").length === 0 && !container.is(e.target) && container.has(e.target).length === 0) {
      
      book_field.find('.item').removeClass('active');
      book_field.find('.container').removeClass('active');
    }
  });

});

})(jQuery);