(function( $ ) {

  $(document).ready(function () {
    // eslint-disable-next-line no-console

    $(".notice-modal").modal();

    $("form #check-in").attr("autocomplete", "off");
    $("form #check-out").attr("autocomplete", "off");

    $('.destination-select').select2({
      minimumResultsForSearch: -1,
      width: 'resolve',
      dropdownPosition: 'below',
      dropdownParent: $('.booking-search'),
      escapeMarkup: function (result) {
        return result;
      },
      templateResult: function (result) {

        if (result['text'] == "Searching…") {
          return '<span class="mad">' + result['text'] + '</span>';
        }
        if (result['text'] == "Add destination") {
          return '<span class="mad">MAD MONKEYS</span>';
        }
        return result['text'].replace(/^(\w+)/, '<span class="country">$1</span>');
      },
    });

    $('.destination-select').on('select2:select', function (e) {
      $("#datepicker").show();

      $(this).parents('.item').removeClass('active');
      $('.booking-search #check-in').parents('.item').addClass('active');
    });

    $('.booking-search .item:first').on('click', function () {
      $('.destination-select').select2('open');
    });

    $('.open-destination').on('click', function (event) {
      event.preventDefault();
      $('.destination-select').select2('open');
      $('.places-item').addClass('active');
      $('.booking-search .container').addClass('active');
    });

    $('.open-calendar').on('click', function (event) {
      event.preventDefault();
      $("#datepicker").attr('style',  'display:inline-flex');
      $('#check-in').parents('.item').addClass('active');
      $('.booking-search .container').addClass('active');
    });

		// mobile validation
		$('#billing_phone').on('keypress', function (e) {
			var $this = $(this);
			var regex = new RegExp("^[+0-9b]+$");
			var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
			// for 11 to 15 digit number only
			if ($this.val().length > 14) {
					e.preventDefault();
					return false;
			}
			if (e.charCode < 54 && e.charCode > 47) {
					if ($this.val().length == 0) {
							e.preventDefault();
							return false;
					} else {
							return true;
					}
			}
			if (regex.test(str)) {
					return true;
			}
			e.preventDefault();
			return false;
		});

		$(document.body).on('updated_checkout', function(data) {

			var ajax_url = madmonkey_tickets_options.ajaxurl,
			country_code = $('#billing_country').val();

			var ajax_data = {
					action: 'append_country_prefix_in_billing_phone',
					country_code: $('#billing_country').val()
			};

			$.post(ajax_url, ajax_data, function(response) {
					$('#billing_phone').val(response);
			});
		});

    // condition for old booking  
    $('#calendar-booking-search').on('submit', function (event) {

      var city = $(".destination-select option:selected").val();
      var checkin = $('#check-in-alter').val();
      var checkout = $('#check-out-alter').val();

      if (city == '41') { 

        var win = window.open("https://hotels.cloudbeds.com/reservation/muXJte#checkin=" + checkin + "&checkout=" + checkout, '_blank');
        win.focus();

        return false;
      }
    });

    // mobile condition for search booking if au
    $('#mcalendar-booking-search').on('submit', function (event) {

      var city = $(".mlist-country option:selected").val();
      var checkin = $('#mcheck-in-alter').val();
      var checkout = $('#mcheck-out-alter').val();

      if (city == '41') {

        var win = window.open("https://hotels.cloudbeds.com/reservation/muXJte#checkin=" + checkin + "&checkout=" + checkout, '_blank');

        win.focus();

        return false;
      }
    });

    if ($('.right .private').length) {
      $('.room-type .item a#private-room').show();
    }

    if ($('.right .dorm').length) {
      $('.room-type .item a#dorm-room').show();
    }

    $('span#read-more').click(function () {

      var id = $(this).attr("data");

      $('#' + id + ' li:hidden').slice(0, 20).show();
      if ($('#' + id + ' li').length == $('#' + id + ' li:visible').length) {
        $('#' + id + ' span#read-more').hide();
      }
    });

    $('.registration-form').hide();
    $('.password-form').hide();
    $('.full-experience').hide();
    $('.welcome-message').hide();

    $('#continue-full-experience').on('click',
      function () {
        $('.deets, .full-experience').slideToggle("slow");
        $('.deets-text').text("Get the full experience");
      }
    );

    $('.create-account, .closeReg').on('click',
      function () {
        $('.popup-login-wrapper, .registration-form').slideToggle("slow");
      }
    );

    $('.lost-password').on('click',
      function () {

        $(".login-modal").modal();

        $('.popup-login-wrapper, .password-form').slideToggle("slow");
      }
    );

    $('.booking-sub-title .login-popup, #error-message .login-popup').on('click',
      function () {

        $(".login-modal").modal();
        $('.popup-login-wrapper').show("slow");
        $('.password-form').hide();
        $('.popup-login-wrapper, .registration-form').slideToggle("slow");

      }
    );

    $('#error-message .lost-password').on('click',
      function () {

        $(".login-modal").modal();
        $('.popup-login-wrapper').hide();
        $('.password-form').show("slow");

      }
    ); 

    $('#legacy-booking button#submit').click(function () {

      var url = $('#destination').val();
      var checkin = $('#check-in-alter').val();
      var checkout = $('#check-out-alter').val();

      var win = window.open("https://hotels.cloudbeds.com/reservation/muXJte#checkin=" + checkin + "&checkout=" + checkout, '_blank');
      win.focus();

      return false;
    });

    $("#woocommerce-form-register #reg_email").keyup(function (e) {

      var email = this.value;

      if (email.length === 0) {
        $('#woocommerce-form-register #reg_email').parents('p').addClass('warning');
      } else {
        $('#woocommerce-form-register #reg_email').parents('p').removeClass('warning');
      }

      if (IsEmail(email) == false) {
        $('.registration-form span.warning.mt-0').removeClass('active');
        $('#woocommerce-form-register #reg_email').parents('p').addClass('warning');
        $('.registration-form span.warning.mt-3').addClass('active');
        $('.woocommerce-form-register__submit').attr('disabled', 'disabled');

        return false;
      } else {
        $('#woocommerce-form-register #reg_email').parents('p').removeClass('warning');
      }

      wp.ajax.post({ action: 'check_lost_password', email }).done(function (response) {
        if (response == 1) {
          $('.registration-form span.warning.mt-3').removeClass('active');
          $('.registration-form span.warning.mt-0').addClass('active');
          $('#woocommerce-form-register #reg_email').parents('p').addClass('warning');
          // $('#woocommerce-form-register #registration-submit').prop('disabled', true);
        } else {
          $('#woocommerce-form-register #reg_email').parents('p').removeClass('warning');
          $('.registration-form span.warning').removeClass('active');
          // $('#woocommerce-form-register #registration-submit').prop('disabled', false);
          $('.woocommerce-form-register__submit').removeAttr('disabled');
        }
      });
    });

    $("#account_password, #reg_password, #password_1").keyup(function (e) {
      var password = this.value;

      wp.ajax.post({ action: 'mdcheck_password_strength', password }).done(function (response) {
        if (/invalid/i.test(response)) {
          $('.woocommerce-form-register__submit').attr('disabled', 'disabled');
        } else {
          $('.woocommerce-form-register__submit').removeAttr('disabled');
        }
        $('ul#password-strength').html(response);
      });
    });

    $("#account_password, #reg_password, #password_1").keyup(function (e) {
      var password = this.value;

      if (e.key === "Backspace") {
        wp.ajax.post({ action: 'mdcheck_password_strength', password }).done(function (response) {
          if (/invalid/i.test(response)) {
            $('.woocommerce-form-register__submit').attr('disabled', 'disabled');
          } else {
            $('.woocommerce-form-register__submit').removeAttr('disabled');
          }
          $('ul#password-strength').html(response);
        });
      }
    });

		const validateEmailFormat = (email) => {
			const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
			return emailRegex.test(email);
		}; 
    
    var emailValidation = $("#billing_email_field label[for='billing_email']");   

    function validateEmail(email) { 
      var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      return emailRegex.test(email);
    }
   
    $("#billing_email").on("input", function() {  
      var email = $(this).val();
      if (validateEmail(email)) {
        emailValidation.html("Email address:").css("color", "#212529");                     
      } else {
        emailValidation.html("Email address: Invalid Email").css("color", "red");        
        $('.shop_table tfoot, .place-order #place_order').addClass('disabled');
      }
    });

    var linkto = GetQueryParamsValue($(location).attr('href'));

    if(linkto.linkto) {

      $('html, body').animate({scrollTop:$('#pills-tab').position().top - 100}, 'slow');
      $('.nav-pills a[href="#cheat-3"]').tab('show');
    }

    // Get the link
    var linkToCopy = $("#copyLink").attr("href");

    // Attach click event to the image
    $("#imageCopyLink").click(function(e) {
      // Create a temporary input element
      var tempInput = $("<input>");

      // Append it to the body
      $("body").append(tempInput);

      // Set its value to the link
      tempInput.val(linkToCopy).select();

      // Copy the link to the clipboard
      document.execCommand("copy");

      // Remove the temporary input element
      tempInput.remove();

      // Optionally, provide some feedback to the user
      alert("Link copied to clipboard!");

      e.preventDefault();
    });

    $('.stars').each(function() {
      var score = $(this).data('score');
      $(this).css('--rating', score / 5);
    });

  });

  // login form validaton
  $(document).on('submit', '#loginform', function (event) {

    var username = $('#loginform #user_login').val();
    var password = $('#loginform #user_pass').val();

    if (username.length === 0) {
      $('#loginform #user_login').parents('p').addClass('warning');
      return false;
    } else {
      $('#loginform #user_login').parents('p').removeClass('warning');
    }

    event.preventDefault();

    wp.ajax.post({ action: 'mdcheck_login_details', username, password }).done(function (response) {

      if (response == 1) {

        event.currentTarget.submit();

      } else if ( response == 3 ) {
        top.location.href = '/my-account/lost-password';
      } else {

        $('.popup-login-wrapper span.warning').addClass('active');
        $('.popup-login-wrapper span.warning').show();
        $('.popup-login-wrapper span.warning.active').fadeOut(5000);

        event.preventDefault();
      }
    });
  });

  $('.woocommerce-ResetPassword-email .woocommerce-Button').click(function () {

    var email = $('.woocommerce-ResetPassword-email #user_login').val();

    if (email.length === 0) {
      $('.woocommerce-ResetPassword-email #user_login').parents('p').addClass('warning');
      return false;
    } else {
      $('.woocommerce-ResetPassword-email #user_login').parents('p').removeClass('warning');
    }

    if (IsEmail(email) == false) {
      $('.woocommerce-ResetPassword-email #user_login').parents('p').addClass('warning');

      return false;
    } else {
      $('.woocommerce-ResetPassword-email #user_login').parents('p').removeClass('warning');
    }

    wp.ajax.post({ action: 'check_lost_password', email }).done(function (response) {

      if (response == 1) {
        $('#woocommerce-ResetPassword').submit();
      } else {

        $('.woocommerce-ResetPassword-email span.warning').addClass('active');

        return false;
      }
    });

    return false;
  });

  // header sticky
  function header_sticky() {
    const scroll = $(window).scrollTop();
    const $topbar = $('.site-header').outerHeight();

    var as = $('.site-header').outerHeight() + 5;
    var advisory = $('.travel-advisory').outerHeight();
    var height = $(window).innerHeight();

    var aa = as + advisory;

    if (scroll) {
      $('.site-header').addClass('active');
      $('.site-header').addClass('smally');
      $('.site-header').addClass('sticky-top');
      $('.sitenav-mobile').css({'margin-top': as + 'px', 'height': height - as + 'px'});
      $('.sitenav-mobile').addClass("sticky-top");
      $('.cart-information').addClass('sticky-enable');
      $('.site-header').show();


      if (scroll > $topbar * 2) {
        $('.site-header').addClass('smally');
      }
    } else {
      $('.site-header').removeClass('active smally sticky-top');
      $('.cart-information').removeClass('sticky-enable');
      $('.sitenav-mobile').css({'margin-top': aa + 'px', 'height': height - aa + 'px'});
      $('.sitenav-mobile').css("height", 'calc(100% - '+ aa +')');
      $('.sitenav-mobile').removeClass("sticky-top");
    }
  }

  $(window).scroll(header_sticky);

  $(window).on("load", function(){
    setTimeout(function(){
      header_sticky();
    }, 100);

    $('input[name="guest_email"]').attr('name', 'guest_email[]');
    $('#guest_email_field').append('<p>Booking with friends? Add additional email <i id="add-guest-email" class="fa-solid fa-circle-plus"></i></p>');

    // console.clear();
  });

  $(window).on("resize", function(){
    header_sticky();
  });

  // intersect
  if ($('#esg-links').length) {
    $(document).on('scroll', function () {

      if ($(this).scrollTop() >= $('#esg-links').position().top) {

        $('#esg-links').addClass('sticky-nav');

      }

      if ($(this).scrollTop() <= 350) {
        $('#esg-links').removeClass('sticky-nav');
      }

    });
  }

  if ($('#mobile-list-country').length) {
    $(document).on('scroll', function () {

      if ($(this).scrollTop() >= $('#mobile-list-country').position().top) {

        $('#mobile-list-country').addClass('sticky-nav');

      }

      if ($(this).scrollTop() <= 350) {
        $('#mobile-list-country').removeClass('sticky-nav');
      }

    });
  }

  if ($('#mnavscroll').length) {
    $(document).on('scroll', function () {

      if ($(this).scrollTop() >= $('#mnavscroll').position().top) {

        $('#mnavscroll').addClass('sticky-nav');

      }

      if ($(this).scrollTop() <= 350) {
        $('#mnavscroll').removeClass('sticky-nav');
      }

    });
  }

  // burger menu
  $('.burger-menu#menu').click(function () {

    $('body').toggleClass('sitenav-mobile-active');

    if ($('body').hasClass('sitenav-mobile-active')) {
      $('body').css('overflow', 'hidden');
      $('.layout-backdrop').attr('aria-hidden', 'false');
    } else {
      $('body').css('overflow', 'auto');
      $('.layout-backdrop').attr('aria-hidden', 'true');
    }

    $('#sitenav-mobile').toggleClass('open');

    $('#sitenav-mobile').removeClass('expand');

  });

  $('.layout-backdrop').click(function () {

    var $backdrop = $('.layout-backdrop');
      $backdrop.attr('aria-hidden',
      $backdrop.attr('aria-hidden')=='false' ? 'true' : 'false'
    );

    $('body').removeClass('sitenav-mobile-active');
    $('body').css('overflow', 'auto');
    $('#sitenav-mobile').removeClass('open');
    $('#sitenav-mobile').removeClass('expand');
    $('.booking-counntry-search').removeClass("active");

  });

  // menu active
  $('#sitenav-mobile li a').click(function () {
    $("#sitenav-mobile li").removeClass("active");
    $(this).parent().addClass("active");
  });

  $(document).on('click', '.not-clickable .menu > .has-sub > a', function(event){

    event.preventDefault();
    event.stopPropagation();
  });

  $(document).ready(function (){

    // Smooth scrolling
    $('li.scroll a, .partners-page a.partner-btn, .auto-scroll').on('click', function (e) {
      e.preventDefault();
      let url = $('body').find($(this).attr('href')).offset().top - 81;

      var posSticky = $('.pos-sticky');

      var elements = $(this).attr('href');

      if($(elements) && posSticky.length) {

        $('html, body').animate({
          scrollTop: url - posSticky.outerHeight()
        }, 900, 'swing');
      } else {

        $('html, body').animate({
          scrollTop: url
        }, 900, 'swing');
      }

      $(this).parent().addClass('active');
      $(this).parent().siblings().removeClass('active');

      var selected = $(this).attr('value');

      if (selected == 'getting-here') {
        $('.nav-pills a[href="#cheat-3"]').tab('show');
      }

      return false;
    });

    var input = document.querySelector("#phone");
    var iti = window.intlTelInput(input, {
        initialCountry: "auto",
        geoIpLookup: function(success, failure) {
            $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
                var countryCode = (resp && resp.country) ? resp.country : "us";
                success(countryCode);
            });
        },
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js" // just for formatting/placeholders etc
    });

    input.addEventListener('countrychange', function(e) {
        // Auto-generate the country code when the country is changed
        $('#phone').val('');
        var countryData = iti.getSelectedCountryData();
        $('#phone').val('+' + countryData.dialCode + ' ' + $('#phone').val());
    });

    $('#woocommerce-form-register').on('submit', function(e) {
      e.preventDefault();

    var nonce = $('#woocommerce-register-nonce').val();
    var email = $('#reg_email').val();
    var password = $('#reg_password').val();
    var billing_first_name = $('#reg_billing_first_name').val();
    var billing_last_name = $('#reg_billing_last_name').val();
    var birthday_field = $('#birthday_field').val();
    var billing_country = $('#billing_country').val();
    var billing_currency = $('#reg_billing_currency').val();
    var billing_phone = $('#phone').val();

    var formData = {
        action: 'mm_register_user',
        nonce,
        email,
        password,
        billing_first_name,
        billing_last_name,
        birthday_field,
        billing_country,
        billing_currency,
        billing_phone,
      };

      wp.ajax.post(formData).done(function(response) {
        if ( response ) {
          $('.full-experience, .welcome-message').slideToggle("slow");
          $('.deets-text').hide();
          window.location.href = response.redirect;
        }
      }).fail(function(response) {
          alert(response.data);
      });

    });

  });

  $(document).on('click', '.shop_table a#more-order-detais', function () {

    $('.shop_table tbody').slideToggle('fast', function () {
      if ($(this).is(':visible'))
        $(this).css('display', 'block');

        $(this).closest('table').toggleClass('active');
      $(".shop_table a#more-order-detais").toggleClass('active', $(this).is(':visible'));

      return false;
    });

  });

  $(document).on('click', '#cart-information a#more-order-detais', function () {

    var nprice = $("p#total-price").attr("total");

    $(this).toggleClass('active');
    $("#cart-information").toggleClass('active');

    $('#cart-information .item-book.check-in').remove();
    $('#cart-information .item-book.check-out').remove();


    $('#cart-information .item-book').slideToggle('fast', function () {
      if ($(this).is(':visible'))
        // $(this).css('display', 'block');
        $('#cart-information .item-book.promo').css('display', 'flex');


      $('#cart-information .item-book.check-in').css('display', 'none');
      $('#cart-information .item-book.check-out').css('display', 'none');

      if (nprice != undefined) {
        $("#cart-information").addClass("active-total");
      } else {
        $("#cart-information").removeClass("active-total");
      }


      return false;

    });

  });

  // Smooth scrolling
  $('.esg-links li.esg a, .talent-header a').click(function (e) {
    e.preventDefault();
    let url = $('body').find($(this).attr('href')).offset().top - 120;
    $('html, body').animate({
      scrollTop: url
    }, 1000);

    return false;
  });

  $(document).on('click', '.card-expss', function (e) {

    var id = $(this).attr("data");
    var index = $(this).attr("index");

    $(".skeleton-2").show();
    $('#experiences-modal .card-content').remove();

    wp.ajax.post({ action: 'destination_selected', id, index }).done(function (response) {

      $('#experiences-modal-content').html(response);
      $(".skeleton-2").hide();

    });
  });

  $(document).on('click', 'a#change-woo-image', function () {
    $("#form-row-pic").toggle();
  });

  $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {

    if (!$(this).next().hasClass('show')) {
      $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
    }

    var $subMenu = $(this).next('.dropdown-menu');
    $subMenu.toggleClass('show');

    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
      $('.dropdown-submenu .show').removeClass('show');
    });

    return false;
  });

  //// added 20.10.2022 /////

  $('.dropdown-toggle').click(function(){

    var sitenav = $('.sitenav-mobile');
    sitenav.removeClass('expand');

  });

  $('.sub-expand .dropdown-toggle').click(function(){

    var myParent = $(this).parent('.sub-expand');
    var sitenav = $('.sitenav-mobile');


    if(myParent.hasClass('show')){

      sitenav.removeClass('expand');
    } else {
      sitenav.addClass('expand');
    }

  });

  $(document).on('click', '#book-now-mobile', function (e) {
    if ($(this).hasClass('active')) { } else {
      return false;
    }
  });

  // freshcaht
  var openWidget = function () {
    document.getElementById('fc_frame').style.visibility = 'visible';
    window.fcWidget.open();
  };

  // validation
  function IsEmail(email) {
    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!regex.test(email)) {
      return false;
    } else {
      return true;
    }
  }

  function format(option) {

    if (!option.id) { return option.text; }
    var ob = option.text + '<img src="https://lh4.ggpht.com/wKrDLLmmxjfRG2-E-k5L5BUuHWpCOe4lWRF7oVs1Gzdn5e5yvr8fj-ORTlBF43U47yI=w64" />';	// replace image source with option.img (available in JSON)
    return ob;
  };

  var isMobile = {
    Android: function () { return navigator.userAgent.match(/Android/i); },
    iOS: function () { return navigator.userAgent.match(/iPhone|iPad|iPod/i); },
    any: function () { return (isMobile.Android() || isMobile.iOS()) }
  };

  var createCookie = function (name, value, days) {
    var expires;
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
    }
    else {
      expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  function getCookie(c_name) {
    if (document.cookie.length > 0) {
      var c_start = document.cookie.indexOf(c_name + "=");
      if (c_start != -1) {
        c_start = c_start + c_name.length + 1;
        var c_end = document.cookie.indexOf(";", c_start);
        if (c_end == -1) {
          c_end = document.cookie.length;
        }
        return unescape(document.cookie.substring(c_start, c_end));
      }
    }
    return "";
  }

  $(document).ready(function () {
    var is_close = false;
    $(window).scroll(function () {
      if (is_close) return;
      var y = $(this).scrollTop();
      var height = $('section.banner').outerHeight();
      if (y > height) {
        $('.cta-banner').slideDown();
      } else {
        $('.cta-banner').slideUp();
      }
    });

    $('.close-cta-popup').on('click', function () {
      $('.cta-banner').fadeOut();
      is_close = true;
    });

    $(document).on('click', function(e){

      var container = $(".cta-banner");

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.fadeOut();
        is_close = true;
      }
    });

    $('.price-content .btn').on('click', function () {
      $(this).addClass('loading');
      $(this).append('<span class="spinner-border"></span>');
    })

    // back page
    $(window).on('pagehide', function() {
      // Remove the class when back button is clicked
      $('.price-content .btn').removeClass('loading');
      $('.price-content .btn .spinner-border').remove();
    });
    

    var title = $('h1.entry-title').text();
    $('p.ez-toc-title').text(title);

    $('.accordion .card .card-header').on('click', function () {
      $('.accordion .card').removeClass('active');

      if (jQuery(this).parent().find('.show').length === 0) {
        jQuery(this).parent().addClass('active');
      }
    });

    $('.woocommerce-edit-account .datepicker').datepicker();

    $("form.edit-account :input").each(function () {
      $(this).change(function () {
        $(this).closest('form').find(':submit').prop('disabled', false);
      });
    });

    $('.safetywing-price-widget').css('display', '');

    $('.login-password').append('<span class="show-password-input"></span>');

    $(document).on('click', '.login-password .show-password-input',
      function() {
        // if ($(this).hasClass('display-password')) {
        //   $(this).removeClass('display-password');
        // } else {
        //   $(this).addClass('display-password');
        // }
        // if ($(this).hasClass('display-password')) {
        //   $(this).siblings(['input[type="password"]']).prop('type', 'text');
        // } else {
        //   $(this).siblings('input[type="text"]').prop('type', 'password');
        // }

        var passwordField = $(this).siblings('.input');
        var fieldType = passwordField.attr('type');

        // Toggle password visibility
        if (fieldType === 'password') {
            passwordField.attr('type', 'text');
            $(this).removeClass('display-password');
            // $(this).removeClass('fa-eye').addClass('fa-eye-slash');
        } else {
            passwordField.attr('type', 'password');
            $(this).addClass('display-password');
            // $(this).removeClass('fa-eye-slash').addClass('fa-eye');
        }
      }
    );

    setTimeout(function () {
      $('.woocommerce-message').fadeOut('fast')
    }, 5000);

    // App Download
    if (getCookie('AppDownload').length <= 0) {
      $('.app-download').slideDown("slow");
    }

    $('.close-app-download').on('click', function (e) {
      e.preventDefault();
      $('.app-download').slideUp("slow");
      createCookie('AppDownload', true, 1);
    });

    $('.app-download .btn').on('click', function () {
      $('.app-download').slideUp("slow");
      createCookie('AppDownload', true, 1);
    });

    // QR Code
    $('.qr-close').on('click', function () {
      $(".qr-code").removeClass('bounceInLeft');

      setTimeout(function () {
        $('.qr-code').hide();
        createCookie('QRcode', true, 1);
      }, 500);
      return false;
    });

    if ( getCookie('QRcode').length != 0 ) {
      $('.qr-code').hide();
      $(".qr-code").removeClass('bounceInLeft');
    }

    // Birthday Promo
    $('.birthday-close').on('click', function () {
      $(".birthday-promo").removeClass('bounceInLeft');

      setTimeout(function () {
        $('.birthday-promo').hide();
        createCookie('birthdaypromo', true, 1);
      }, 500);
      return false;
    });

    if ( getCookie('birthdaypromo').length != 0 ) {
      $('.birthday-promo').hide();
      $(".birthday-promo").removeClass('bounceInLeft');
    }

    // Madventure Navigation Filter
    $('.exp-nav .menu > .has-sub > a').click(function(event){
      event.preventDefault();
    });

    // Backpackers Guide Navigation
    $('.sitenav .has-sub .arrow').click(function(event){
      event.preventDefault();
      event.stopPropagation();

      $(this).closest('.navbar').addClass('top-index');
    });

    // Backpackers Guide Navigation click outside
    $(document).click(function(event){

      if(!$(event.target).closest('.sitenav .has-sub .arrow').length){
        $('.sitenav .has-sub .arrow').closest('.navbar').removeClass('top-index');
      }

    })

  });

  // auto scroll when click search booking
  $('.booking-search.revamp .item-check input, .booking-search.revamp .item-check .date, .card-destination .open-destination, .booking-search.revamp .places-item').click(function(){

    if(!$('.bookig-search-holder').hasClass('fixed')) {

      $('html, body').animate({
        scrollTop: 200
      }, 500, 'easeInOutQuart');
    }
  });

  // custom function
  function GetQueryParamsValue(url) {
      const paramArr = url.slice(url.indexOf('?') + 1).split('&');
      const params = {};
      paramArr.map(param => {
          const [key, val] = param.split('=');
          params[key] = decodeURIComponent(val);
      })
      return params;
  }

  // On Modal Close

  $('.modal.faq').on('hidden.bs.modal', function () {

    $('.collapse').collapse('hide');
    $('.accordion .card').removeClass('active');
  });
    
  // deals country filter
  $('#deal-country-filter .country-filter li a#country').click(function(event) {

    var ids = $(this).attr( "data" ).slice(0, -1);    
    var prodids = $(this).attr( "prodids" ).slice(0, -1);
    var pageid = $(this).attr( "pageid" );  
    
    var active = document.querySelector('#deal-country-filter .country-filter li a.active');

    if(active){
      
      active.classList.remove('active');
    }

    event.currentTarget.classList.add('active');

    $.ajax({ 
        type: "POST",
        url: madmonkey_tickets_options.ajaxurl,
        data: {
            action: 'deal_page_results', 
            ids : ids,
            prodids : prodids,
            pageid : pageid
    },
    beforeSend: function() {
        $('#section-promo-sliders .section').attr('style','visibility: hidden');
        $("#spinner-ajax").show();
    }, 
    complete: function() {
        $("#spinner-ajax").hide();
        $('#section-promo-sliders .section').attr('style','visibility: visible'); 
    },
    success: function( response ) {         
      
      $('#section-promo-sliders').html(response);       
      $('body').swipeBooking();
    
    },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
          console.log("Status: " + textStatus);
          console.log("Error: " + errorThrown);
        }
    });

  });


  // remove autoscroll to top on load
  $(window).on('beforeunload', function(){
    sessionStorage.removeItem("scrollPos");
  });


  $(window).scroll(function(){
    var scrollPos = document.querySelector("html").scrollTop;
    
	  sessionStorage.setItem("scrollPos", scrollPos);
  });

  $(window).on('load',function(){
    
    var getscrollPos = sessionStorage.getItem("scrollPos");

    if (getscrollPos) {

      document.querySelector("html").scrollTop = getscrollPos;

      setTimeout(function(){
        sessionStorage.removeItem("scrollPos");
      }, 500);
    }

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })

  });

})(jQuery);

