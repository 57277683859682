var event_close = (function(document, window) {

	var evt = [

		function(document, window) {

			var toggle = document.querySelectorAll('[data-toggle=close]');

			toggle.forEach(function(close){

				var target = document.querySelector('.' + close.getAttribute('aria-controls'));

				close.addEventListener('click', function(event){
          event.preventDefault();
          event.stopPropagation();

					target.classList.remove('show');

					document.querySelector('body').classList.remove('overlap-hidden');
					document.querySelector('.layout-backdrop').setAttribute('aria-hidden', true);
					document.querySelector('.layout-backdrop').classList.remove('override');
				});
			});			

		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

event_close.init();