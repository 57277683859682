
var event_collapse = (function(document, window) {

	var evt = [

		function(document, window) {

			// Collapse class
			var toggle = document.querySelectorAll('[data-toggle=collapse]');

			toggle.forEach(function(collapse){

				if(collapse.getAttribute('aria-controls')) {

					var target = document.querySelector('.' + collapse.getAttribute('aria-controls'));
	
					collapse.addEventListener('click', function(){
	
						target.classList.add('show');
					});
				}

			});


			// Toggle class
			var toggle = document.querySelectorAll('[data-toggle=toggle]');

			toggle.forEach(function(collapse){

				var parent = collapse.closest('.order');
				var targets = parent.querySelectorAll('.' + collapse.getAttribute('aria-controls'));

				collapse.addEventListener('click', function(){

					collapse.classList.toggle('open');

					targets.forEach(function(target){

						target.classList.toggle('show');
					});
				});
			});

			// Drawer class
			document.addEventListener('click', function (e) {
				var target = e.target;
		
				// Check if the clicked element or its ancestor has the attribute data-toggle with the value 'drawer'
				var toggle = findClosestToggle(target);
				
				if (toggle) {
						e.preventDefault();
		
						var panels = document.querySelectorAll('.' + toggle.getAttribute('aria-controls'));
		
						panels.forEach(function (panel) {
								panel.classList.add('show');
						});
		
						document.body.classList.add('overlap-hidden');
		
						var layoutBackdrop = document.querySelector('.layout-backdrop');
						if (layoutBackdrop) {
								layoutBackdrop.classList.add('override');
						}
				}
			});
			
			// Function to find the closest ancestor with data-toggle attribute equal to 'drawer'
			function findClosestToggle(element) {
					while (element && !element.hasAttribute('data-toggle')) {
							element = element.parentElement;
					}
					return element && element.getAttribute('data-toggle') === 'drawer' ? element : null;
			}

			
			// OverRide
			
			document.addEventListener('click', function (e) {
				var target = e.target;
		
				// Check if the clicked element has the class 'layout-backdrop' and the class 'override'
				if (target.classList.contains('layout-backdrop') && target.classList.contains('override')) {
						e.preventDefault();
		
						var popupPanels = document.querySelectorAll('.popup-panel');
						popupPanels.forEach(function (panel) {
								panel.classList.remove('show');
						});
		
						document.body.classList.remove('overlap-hidden');
		
						var layoutBackdrop = document.querySelector('.layout-backdrop');
						if (layoutBackdrop) {
								layoutBackdrop.setAttribute('aria-hidden', true);
								layoutBackdrop.classList.remove('override');
						}
				}
			});
		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

event_collapse.init();
