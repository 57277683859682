(function( $ ) {

    'use strict';

    document.addEventListener("DOMContentLoaded", function() {
        var markers = document.querySelectorAll('.marker');
        var mapOptions = {
            center: new google.maps.LatLng(-6.616829, 121.407260),
            zoom: 4,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [{
                    "stylers": [{
                            "visibility": "on"
                        },
                        {
                            "saturation": -100
                        },
                        {
                            "gamma": 0.54
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "water",
                    "stylers": [{
                        "color": "#4d4946"
                    }]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.icon",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text",
                    "stylers": [{
                        "visibility": "simplified"
                    }]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.fill",
                    "stylers": [{
                        "color": "#ffffff"
                    }]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text",
                    "stylers": [{
                        "visibility": "simplified"
                    }]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#ffffff"
                    }]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [{
                        "gamma": 0.48
                    }]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "labels.icon",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.stroke",
                    "stylers": [{
                        "gamma": 7.18
                    }]
                }
            ],
        };

        function smoothPan(map, targetLatLng) {
            var currentLatLng = map.getCenter();
            var duration = 300;
            var increment = 0.001;
            var steps = duration / 20;
            var stepLat = (targetLatLng.lat() - currentLatLng.lat()) / steps;
            var stepLng = (targetLatLng.lng() - currentLatLng.lng()) / steps;
            var i = 0;

            var interval = setInterval(function() {
                if (i < steps) {
                    currentLatLng = new google.maps.LatLng(currentLatLng.lat() + stepLat, currentLatLng.lng() + stepLng);
                    map.setCenter(currentLatLng);
                    i++;
                } else {
                    clearInterval(interval);
                }
            }, 10);
        }

        var map = new google.maps.Map(document.querySelector('#google-map'), mapOptions);
        var markers = document.querySelectorAll('.marker');
        var currentInfoWindow = null;

        const image = "/wp-content/themes/madmonkey-tickets/assets/images/map-marker.png";

        markers.forEach(function(markerElem, index) { 

            var lat = parseFloat(markerElem.getAttribute('data-lat'));
            var lng = parseFloat(markerElem.getAttribute('data-lng'));
            var position = new google.maps.LatLng(lat, lng);
            
            var marker = new google.maps.Marker({
                position: position,
                map: map,
                animation: google.maps.Animation.DROP,
                icon: image,
            }); 

            var infoWindowContent = `
                <div>
                    <h5>${markerElem.getAttribute("data-title")}</h4>
                    <p>${markerElem.getAttribute("data-address")}</p>
                    <p><a href="https://www.google.com/maps/search/?api=1&query=${markerElem.getAttribute("data-address")}" target="_blank"> Get Direction </a></p>
                </div>
            `;

            var infoWindow = new google.maps.InfoWindow({
                content: infoWindowContent
            });

            //Info Window
            marker.addListener("click", function() {
                if (currentInfoWindow) {
                    currentInfoWindow.close();
                }
                infoWindow.open(map, marker);
                currentInfoWindow = infoWindow;
            });

        });

        var swiper_google_map = document.querySelector('.swiper-google-map');

        var perviewSm = swiper_google_map.getAttribute('data-sm-perview');
        var perviewMd = swiper_google_map.getAttribute('data-md-perview');
        var perviewLg = swiper_google_map.getAttribute('data-lg-perview');
        var loop = swiper_google_map.getAttribute('data-loop');
        var gap = swiper_google_map.getAttribute('data-gap');
        var height = swiper_google_map.getAttribute('data-autoheight');
        var pagination = swiper_google_map.querySelector('.swiper-pagination');
        var effect = swiper_google_map.getAttribute('data-effect');
        var delay = swiper_google_map.getAttribute('data-delay');
        var autoplay = swiper_google_map.getAttribute('data-autoplay');
        var swipe_slide  = swiper_google_map.querySelectorAll('.swiper-slide');

        var new_loop = JSON.parse(loop);
        var new_gap = parseFloat(gap);
        var new_height = JSON.parse(height);
        var new_autoplay = JSON.parse(autoplay);


        var swiper = new Swiper(swiper_google_map, {
            spaceBetween: new_gap,
            slidesPerView: perviewSm,
            effect: effect,
            centeredSlides: true,
            speed: 500,
            loop: new_loop,
            autoHeight: new_height,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            autoplay: {
                enabled: false,
                delay: delay,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            },
            pagination: {
                el: pagination,
                clickable: true,
            },
            breakpoints: {
                560: {
                    slidesPerView: perviewSm,
                    autoplay: {
                        enabled: false,
                        delay: delay,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: false,
                    },
                },
                576: {
                    slidesPerView: perviewMd,
                    autoplay: {
                        enabled: false,
                        delay: delay,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: false,
                    },
                },
                1024: {
                    slidesPerView: perviewLg,
                    autoplay: {
                        enabled: new_autoplay,
                        delay: delay,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: false,
                    },
                },
            },
            on: {
                slideChange: function() {

                    if (swiper && swiper.slides) {
                        var currentSlide = swiper.slides[swiper.activeIndex];
                        var slideId = currentSlide.getAttribute('data-slide-id');

                        var marker = document.querySelector('.marker[data-marker-id="' + slideId + '"]');

                        if (marker) {
                            var lat = parseFloat(marker.getAttribute('data-lat'));
                            var lng = parseFloat(marker.getAttribute('data-lng'));
                            var position = new google.maps.LatLng(lat, lng);

                            setTimeout(function() {
                                smoothPan(map, position);

                                var markers = new google.maps.Marker({
                                    position: position,
                                    map: map,
                                    icon: image,
                                });

                                var infoWindowContent = `
                                    <div class="custom-infowindow">
                                        <h5>${marker.getAttribute("data-title")}</h4>
                                        <p>${marker.getAttribute("data-address")}</p>
                                        <a href="https://www.google.com/maps/search/?api=1&query=${marker.getAttribute("data-address")}" target="_blank"> Get Direction </a>
                                    </div>
                                `;

                                var infoWindow = new google.maps.InfoWindow({
                                    content: infoWindowContent
                                });

                                // Close the current Info Window if it's open
                                if (currentInfoWindow) {
                                    currentInfoWindow.close();
                                }

                                // Open the new Info Window
                                infoWindow.open(map, markers);
                                infoWindow.setContent(infoWindowContent);    
                                currentInfoWindow = infoWindow;
                    
                            }, 100);

                        }

                    } else {
                        // console.error('Swiper is not initialized or there are no slides.');
                    }
                }
            }
        });
    });

})(jQuery);