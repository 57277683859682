var event_videos = (function(document, window) {

	var evt = [

		function(document, window) {

			var toggle = document.querySelectorAll('.video-player');

			toggle.forEach(function(videos){

				videos.controls = false;
			});

		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

event_videos.init();